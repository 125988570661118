/* tslint:disable */
/* eslint-disable */
/**
 * ValData API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum SelectEnum {
    All = 'all',
    LastDays = 'last_days',
    LastGames = 'last_games'
}

export function SelectEnumFromJSON(json: any): SelectEnum {
    return SelectEnumFromJSONTyped(json, false);
}

export function SelectEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): SelectEnum {
    return json as SelectEnum;
}

export function SelectEnumToJSON(value?: SelectEnum | null): any {
    return value as any;
}

