/* tslint:disable */
/* eslint-disable */
/**
 * ValData API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GeneralStats
 */
export interface GeneralStats {
    /**
     * 
     * @type {string}
     * @memberof GeneralStats
     */
    map: string | null;
    /**
     * 
     * @type {string}
     * @memberof GeneralStats
     */
    character: string | null;
    /**
     * 
     * @type {number}
     * @memberof GeneralStats
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof GeneralStats
     */
    winrate: number | null;
    /**
     * 
     * @type {number}
     * @memberof GeneralStats
     */
    attackWinrate: number | null;
    /**
     * 
     * @type {number}
     * @memberof GeneralStats
     */
    defenceWinrate: number | null;
    /**
     * 
     * @type {number}
     * @memberof GeneralStats
     */
    acs: number | null;
    /**
     * 
     * @type {number}
     * @memberof GeneralStats
     */
    kda: number | null;
    /**
     * 
     * @type {number}
     * @memberof GeneralStats
     */
    adr: number | null;
    /**
     * 
     * @type {number}
     * @memberof GeneralStats
     */
    kast: number | null;
    /**
     * 
     * @type {number}
     * @memberof GeneralStats
     */
    kpr: number | null;
    /**
     * 
     * @type {number}
     * @memberof GeneralStats
     */
    apr: number | null;
    /**
     * 
     * @type {number}
     * @memberof GeneralStats
     */
    fkpr: number | null;
    /**
     * 
     * @type {number}
     * @memberof GeneralStats
     */
    fdpr: number | null;
}

export function GeneralStatsFromJSON(json: any): GeneralStats {
    return GeneralStatsFromJSONTyped(json, false);
}

export function GeneralStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): GeneralStats {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'map': json['map'],
        'character': json['character'],
        'count': json['count'],
        'winrate': json['winrate'],
        'attackWinrate': json['attack_winrate'],
        'defenceWinrate': json['defence_winrate'],
        'acs': json['acs'],
        'kda': json['kda'],
        'adr': json['adr'],
        'kast': json['kast'],
        'kpr': json['kpr'],
        'apr': json['apr'],
        'fkpr': json['fkpr'],
        'fdpr': json['fdpr'],
    };
}

export function GeneralStatsToJSON(value?: GeneralStats | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'map': value.map,
        'character': value.character,
        'count': value.count,
        'winrate': value.winrate,
        'attack_winrate': value.attackWinrate,
        'defence_winrate': value.defenceWinrate,
        'acs': value.acs,
        'kda': value.kda,
        'adr': value.adr,
        'kast': value.kast,
        'kpr': value.kpr,
        'apr': value.apr,
        'fkpr': value.fkpr,
        'fdpr': value.fdpr,
    };
}

