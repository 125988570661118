/* tslint:disable */
/* eslint-disable */
/**
 * ValData API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BasicUser,
    BasicUserFromJSON,
    BasicUserFromJSONTyped,
    BasicUserToJSON,
} from './BasicUser';
import {
    Player,
    PlayerFromJSON,
    PlayerFromJSONTyped,
    PlayerToJSON,
} from './Player';

/**
 * 
 * @export
 * @interface MatchGroup
 */
export interface MatchGroup {
    /**
     * 
     * @type {number}
     * @memberof MatchGroup
     */
    readonly id: number;
    /**
     * 
     * @type {BasicUser}
     * @memberof MatchGroup
     */
    readonly creator: BasicUser | null;
    /**
     * 
     * @type {number}
     * @memberof MatchGroup
     */
    readonly matchCount: number;
    /**
     * 
     * @type {Array<Player>}
     * @memberof MatchGroup
     */
    linkedPlayers?: Array<Player>;
    /**
     * 
     * @type {string}
     * @memberof MatchGroup
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof MatchGroup
     */
    imageId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MatchGroup
     */
    automaticLinking?: boolean;
}

export function MatchGroupFromJSON(json: any): MatchGroup {
    return MatchGroupFromJSONTyped(json, false);
}

export function MatchGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): MatchGroup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'creator': BasicUserFromJSON(json['creator']),
        'matchCount': json['match_count'],
        'linkedPlayers': !exists(json, 'linked_players') ? undefined : ((json['linked_players'] as Array<any>).map(PlayerFromJSON)),
        'name': json['name'],
        'imageId': !exists(json, 'image_id') ? undefined : json['image_id'],
        'automaticLinking': !exists(json, 'automatic_linking') ? undefined : json['automatic_linking'],
    };
}

export function MatchGroupToJSON(value?: MatchGroup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'linked_players': value.linkedPlayers === undefined ? undefined : ((value.linkedPlayers as Array<any>).map(PlayerToJSON)),
        'name': value.name,
        'image_id': value.imageId,
        'automatic_linking': value.automaticLinking,
    };
}

