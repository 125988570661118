/* tslint:disable */
/* eslint-disable */
/**
 * ValData API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum TeamEnum {
    B = 'B',
    R = 'R'
}

export function TeamEnumFromJSON(json: any): TeamEnum {
    return TeamEnumFromJSONTyped(json, false);
}

export function TeamEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeamEnum {
    return json as TeamEnum;
}

export function TeamEnumToJSON(value?: TeamEnum | null): any {
    return value as any;
}

