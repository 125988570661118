/* tslint:disable */
/* eslint-disable */
/**
 * ValData API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TeamMapping,
    TeamMappingFromJSON,
    TeamMappingFromJSONTyped,
    TeamMappingToJSON,
} from './TeamMapping';

/**
 * 
 * @export
 * @interface Match
 */
export interface Match {
    /**
     * 
     * @type {string}
     * @memberof Match
     */
    id: string;
    /**
     * 
     * @type {TeamMapping}
     * @memberof Match
     */
    readonly playerData: TeamMapping | null;
    /**
     * 
     * @type {number}
     * @memberof Match
     */
    readonly totalRounds: number;
    /**
     * 
     * @type {string}
     * @memberof Match
     */
    cluster: string;
    /**
     * 
     * @type {string}
     * @memberof Match
     */
    mode: string;
    /**
     * 
     * @type {string}
     * @memberof Match
     */
    map: string;
    /**
     * 
     * @type {number}
     * @memberof Match
     */
    length: number;
    /**
     * 
     * @type {Date}
     * @memberof Match
     */
    startTime: Date;
    /**
     * 
     * @type {number}
     * @memberof Match
     */
    redScore: number;
    /**
     * 
     * @type {number}
     * @memberof Match
     */
    blueScore: number;
}

export function MatchFromJSON(json: any): Match {
    return MatchFromJSONTyped(json, false);
}

export function MatchFromJSONTyped(json: any, ignoreDiscriminator: boolean): Match {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'playerData': TeamMappingFromJSON(json['player_data']),
        'totalRounds': json['total_rounds'],
        'cluster': json['cluster'],
        'mode': json['mode'],
        'map': json['map'],
        'length': json['length'],
        'startTime': (new Date(json['start_time'])),
        'redScore': json['red_score'],
        'blueScore': json['blue_score'],
    };
}

export function MatchToJSON(value?: Match | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'cluster': value.cluster,
        'mode': value.mode,
        'map': value.map,
        'length': value.length,
        'start_time': (value.startTime.toISOString()),
        'red_score': value.redScore,
        'blue_score': value.blueScore,
    };
}

