/* tslint:disable */
/* eslint-disable */
/**
 * ValData API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Line,
    LineFromJSON,
    LineFromJSONTyped,
    LineToJSON,
} from './Line';

/**
 * 
 * @export
 * @interface LineStats
 */
export interface LineStats {
    /**
     * 
     * @type {string}
     * @memberof LineStats
     */
    puuid: string;
    /**
     * 
     * @type {Array<Line>}
     * @memberof LineStats
     */
    lines: Array<Line>;
}

export function LineStatsFromJSON(json: any): LineStats {
    return LineStatsFromJSONTyped(json, false);
}

export function LineStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): LineStats {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'puuid': json['puuid'],
        'lines': ((json['lines'] as Array<any>).map(LineFromJSON)),
    };
}

export function LineStatsToJSON(value?: LineStats | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'puuid': value.puuid,
        'lines': ((value.lines as Array<any>).map(LineToJSON)),
    };
}

