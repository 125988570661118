/* tslint:disable */
/* eslint-disable */
/**
 * ValData API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    View,
    ViewFromJSON,
    ViewFromJSONTyped,
    ViewToJSON,
} from './View';

/**
 * 
 * @export
 * @interface ViewStats
 */
export interface ViewStats {
    /**
     * 
     * @type {string}
     * @memberof ViewStats
     */
    puuid: string;
    /**
     * 
     * @type {Array<View>}
     * @memberof ViewStats
     */
    views: Array<View>;
}

export function ViewStatsFromJSON(json: any): ViewStats {
    return ViewStatsFromJSONTyped(json, false);
}

export function ViewStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ViewStats {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'puuid': json['puuid'],
        'views': ((json['views'] as Array<any>).map(ViewFromJSON)),
    };
}

export function ViewStatsToJSON(value?: ViewStats | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'puuid': value.puuid,
        'views': ((value.views as Array<any>).map(ViewToJSON)),
    };
}

