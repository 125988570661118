/* tslint:disable */
/* eslint-disable */
/**
 * ValData API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Point,
    PointFromJSON,
    PointFromJSONTyped,
    PointToJSON,
} from './Point';

/**
 * 
 * @export
 * @interface PointStats
 */
export interface PointStats {
    /**
     * 
     * @type {string}
     * @memberof PointStats
     */
    puuid: string;
    /**
     * 
     * @type {Array<Point>}
     * @memberof PointStats
     */
    points: Array<Point>;
}

export function PointStatsFromJSON(json: any): PointStats {
    return PointStatsFromJSONTyped(json, false);
}

export function PointStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PointStats {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'puuid': json['puuid'],
        'points': ((json['points'] as Array<any>).map(PointFromJSON)),
    };
}

export function PointStatsToJSON(value?: PointStats | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'puuid': value.puuid,
        'points': ((value.points as Array<any>).map(PointToJSON)),
    };
}

