/* tslint:disable */
/* eslint-disable */
/**
 * ValData API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    LineStats,
    LineStatsFromJSON,
    LineStatsToJSON,
    MapStats,
    MapStatsFromJSON,
    MapStatsToJSON,
    ModeEnum,
    ModeEnumFromJSON,
    ModeEnumToJSON,
    PointStats,
    PointStatsFromJSON,
    PointStatsToJSON,
    ResultEnum,
    ResultEnumFromJSON,
    ResultEnumToJSON,
    SelectEnum,
    SelectEnumFromJSON,
    SelectEnumToJSON,
    SideEnum,
    SideEnumFromJSON,
    SideEnumToJSON,
    TypeEnum,
    TypeEnumFromJSON,
    TypeEnumToJSON,
    ViewStats,
    ViewStatsFromJSON,
    ViewStatsToJSON,
} from '../models';

export interface StatsGeneralRetrieveRequest {
    id: string;
    map: string;
    type: TypeEnum;
    mode?: ModeEnum;
}

export interface StatsLinesListRequest {
    id: string;
    lineType: StatsLinesListLineTypeEnum;
    map: string;
    type: TypeEnum;
    mode?: ModeEnum;
    result?: ResultEnum;
    select?: SelectEnum;
    selectParam?: string;
    side?: SideEnum;
}

export interface StatsPointsListRequest {
    id: string;
    map: string;
    pointType: StatsPointsListPointTypeEnum;
    type: TypeEnum;
    mode?: ModeEnum;
    result?: ResultEnum;
    select?: SelectEnum;
    selectParam?: string;
    side?: SideEnum;
}

export interface StatsViewsListRequest {
    id: string;
    map: string;
    type: TypeEnum;
    viewType: StatsViewsListViewTypeEnum;
    mode?: ModeEnum;
    result?: ResultEnum;
    select?: SelectEnum;
    selectParam?: string;
    side?: SideEnum;
}

/**
 * 
 */
export class StatsApi extends runtime.BaseAPI {

    /**
     */
    async statsGeneralRetrieveRaw(requestParameters: StatsGeneralRetrieveRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MapStats>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling statsGeneralRetrieve.');
        }

        if (requestParameters.map === null || requestParameters.map === undefined) {
            throw new runtime.RequiredError('map','Required parameter requestParameters.map was null or undefined when calling statsGeneralRetrieve.');
        }

        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling statsGeneralRetrieve.');
        }

        const queryParameters: any = {};

        if (requestParameters.mode !== undefined) {
            queryParameters['mode'] = requestParameters.mode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stats/{type}/{id}/{map}/general/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"map"}}`, encodeURIComponent(String(requestParameters.map))).replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MapStatsFromJSON(jsonValue));
    }

    /**
     */
    async statsGeneralRetrieve(requestParameters: StatsGeneralRetrieveRequest, initOverrides?: RequestInit): Promise<MapStats> {
        const response = await this.statsGeneralRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async statsLinesListRaw(requestParameters: StatsLinesListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<LineStats>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling statsLinesList.');
        }

        if (requestParameters.lineType === null || requestParameters.lineType === undefined) {
            throw new runtime.RequiredError('lineType','Required parameter requestParameters.lineType was null or undefined when calling statsLinesList.');
        }

        if (requestParameters.map === null || requestParameters.map === undefined) {
            throw new runtime.RequiredError('map','Required parameter requestParameters.map was null or undefined when calling statsLinesList.');
        }

        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling statsLinesList.');
        }

        const queryParameters: any = {};

        if (requestParameters.mode !== undefined) {
            queryParameters['mode'] = requestParameters.mode;
        }

        if (requestParameters.result !== undefined) {
            queryParameters['result'] = requestParameters.result;
        }

        if (requestParameters.select !== undefined) {
            queryParameters['select'] = requestParameters.select;
        }

        if (requestParameters.selectParam !== undefined) {
            queryParameters['select_param'] = requestParameters.selectParam;
        }

        if (requestParameters.side !== undefined) {
            queryParameters['side'] = requestParameters.side;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stats/{type}/{id}/{map}/lines/{line_type}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"line_type"}}`, encodeURIComponent(String(requestParameters.lineType))).replace(`{${"map"}}`, encodeURIComponent(String(requestParameters.map))).replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LineStatsFromJSON));
    }

    /**
     */
    async statsLinesList(requestParameters: StatsLinesListRequest, initOverrides?: RequestInit): Promise<Array<LineStats>> {
        const response = await this.statsLinesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async statsPointsListRaw(requestParameters: StatsPointsListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<PointStats>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling statsPointsList.');
        }

        if (requestParameters.map === null || requestParameters.map === undefined) {
            throw new runtime.RequiredError('map','Required parameter requestParameters.map was null or undefined when calling statsPointsList.');
        }

        if (requestParameters.pointType === null || requestParameters.pointType === undefined) {
            throw new runtime.RequiredError('pointType','Required parameter requestParameters.pointType was null or undefined when calling statsPointsList.');
        }

        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling statsPointsList.');
        }

        const queryParameters: any = {};

        if (requestParameters.mode !== undefined) {
            queryParameters['mode'] = requestParameters.mode;
        }

        if (requestParameters.result !== undefined) {
            queryParameters['result'] = requestParameters.result;
        }

        if (requestParameters.select !== undefined) {
            queryParameters['select'] = requestParameters.select;
        }

        if (requestParameters.selectParam !== undefined) {
            queryParameters['select_param'] = requestParameters.selectParam;
        }

        if (requestParameters.side !== undefined) {
            queryParameters['side'] = requestParameters.side;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stats/{type}/{id}/{map}/points/{point_type}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"map"}}`, encodeURIComponent(String(requestParameters.map))).replace(`{${"point_type"}}`, encodeURIComponent(String(requestParameters.pointType))).replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PointStatsFromJSON));
    }

    /**
     */
    async statsPointsList(requestParameters: StatsPointsListRequest, initOverrides?: RequestInit): Promise<Array<PointStats>> {
        const response = await this.statsPointsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async statsViewsListRaw(requestParameters: StatsViewsListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ViewStats>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling statsViewsList.');
        }

        if (requestParameters.map === null || requestParameters.map === undefined) {
            throw new runtime.RequiredError('map','Required parameter requestParameters.map was null or undefined when calling statsViewsList.');
        }

        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling statsViewsList.');
        }

        if (requestParameters.viewType === null || requestParameters.viewType === undefined) {
            throw new runtime.RequiredError('viewType','Required parameter requestParameters.viewType was null or undefined when calling statsViewsList.');
        }

        const queryParameters: any = {};

        if (requestParameters.mode !== undefined) {
            queryParameters['mode'] = requestParameters.mode;
        }

        if (requestParameters.result !== undefined) {
            queryParameters['result'] = requestParameters.result;
        }

        if (requestParameters.select !== undefined) {
            queryParameters['select'] = requestParameters.select;
        }

        if (requestParameters.selectParam !== undefined) {
            queryParameters['select_param'] = requestParameters.selectParam;
        }

        if (requestParameters.side !== undefined) {
            queryParameters['side'] = requestParameters.side;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stats/{type}/{id}/{map}/views/{view_type}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"map"}}`, encodeURIComponent(String(requestParameters.map))).replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))).replace(`{${"view_type"}}`, encodeURIComponent(String(requestParameters.viewType))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ViewStatsFromJSON));
    }

    /**
     */
    async statsViewsList(requestParameters: StatsViewsListRequest, initOverrides?: RequestInit): Promise<Array<ViewStats>> {
        const response = await this.statsViewsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum StatsLinesListLineTypeEnum {
    Deaths = 'deaths',
    Kills = 'kills'
}
/**
    * @export
    * @enum {string}
    */
export enum StatsPointsListPointTypeEnum {
    DeathsOther = 'deaths_other',
    DeathsOwn = 'deaths_own',
    KillsOther = 'kills_other',
    KillsOwn = 'kills_own',
    PosOnDefuse = 'pos_on_defuse',
    PosOnFirstKill = 'pos_on_first_kill',
    PosOnPlant = 'pos_on_plant',
    Spike = 'spike'
}
/**
    * @export
    * @enum {string}
    */
export enum StatsViewsListViewTypeEnum {
    Awp = 'awp'
}
