/* tslint:disable */
/* eslint-disable */
/**
 * ValData API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserMapStats,
    UserMapStatsFromJSON,
    UserMapStatsFromJSONTyped,
    UserMapStatsToJSON,
} from './UserMapStats';

/**
 * 
 * @export
 * @interface MapStats
 */
export interface MapStats {
    /**
     * 
     * @type {Array<UserMapStats>}
     * @memberof MapStats
     */
    mapping: Array<UserMapStats>;
    /**
     * 
     * @type {number}
     * @memberof MapStats
     */
    count: number;
    /**
     * 
     * @type {number}
     * @memberof MapStats
     */
    wins: number;
    /**
     * 
     * @type {number}
     * @memberof MapStats
     */
    losses: number;
    /**
     * 
     * @type {number}
     * @memberof MapStats
     */
    ties: number;
    /**
     * 
     * @type {number}
     * @memberof MapStats
     */
    winrate: number;
    /**
     * 
     * @type {number}
     * @memberof MapStats
     */
    attackWr: number;
    /**
     * 
     * @type {number}
     * @memberof MapStats
     */
    defenceWr: number;
}

export function MapStatsFromJSON(json: any): MapStats {
    return MapStatsFromJSONTyped(json, false);
}

export function MapStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): MapStats {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'mapping': ((json['mapping'] as Array<any>).map(UserMapStatsFromJSON)),
        'count': json['count'],
        'wins': json['wins'],
        'losses': json['losses'],
        'ties': json['ties'],
        'winrate': json['winrate'],
        'attackWr': json['attack_wr'],
        'defenceWr': json['defence_wr'],
    };
}

export function MapStatsToJSON(value?: MapStats | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mapping': ((value.mapping as Array<any>).map(UserMapStatsToJSON)),
        'count': value.count,
        'wins': value.wins,
        'losses': value.losses,
        'ties': value.ties,
        'winrate': value.winrate,
        'attack_wr': value.attackWr,
        'defence_wr': value.defenceWr,
    };
}

