import {
  CSSProperties,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from "react";
import { Link, useNavigate } from "react-router-dom";

import { GameApi, Player } from "api";

import { useConfig } from "providers/ConfigProvider";

import PlayerCardImage from "./PlayerCardImage";

export function PlayerSearchForm({
  className,
  children,
}: PropsWithChildren<{ className?: string }>) {
  const navigate = useNavigate();
  const ref = useRef<HTMLFormElement>(null);
  return (
    <form
      ref={ref}
      className={`${className ?? ""} player-search-form`}
      onSubmit={(e) => {
        e.preventDefault();

        const input = ref.current?.querySelector("input.player-search-input");
        if (!input) return;

        const fullName = (input as HTMLInputElement).value;
        if (!fullName.includes("#")) return;

        const [username, tag] = fullName.split("#");
        const usernameEncoded = encodeURIComponent(username);
        navigate(`/user/${usernameEncoded}/${tag}/`);
      }}
    >
      {children}
    </form>
  );
}

export function PlayerSearchButton({
  className,
  disabled,
  style,
}: {
  className?: string;
  disabled?: boolean;
  style?: CSSProperties;
}) {
  return (
    <input
      type="submit"
      aria-label="Look for the player"
      className={`${className ?? ""} btn player-search-button`}
      disabled={disabled}
      value="Search"
      style={style}
    />
  );
}

export function PlayerSearchInput({
  className,
  onClick,
  isLink,
  disabled,
}: {
  className?: string;
  onClick?: (player: Player, close: () => void) => void;
  isLink?: boolean;
  disabled?: boolean;
}) {
  const [inputValue, setInputValue] = useState("");
  const [searchResults, setSearchResults] = useState<Player[]>();
  const [showResults, setShowResults] = useState(false);
  const { apiConfiguration } = useConfig();

  const visibleResults = searchResults !== undefined && showResults;

  useEffect(() => {
    if (inputValue.length < 3) {
      setSearchResults(undefined);
    } else {
      const gameApi = new GameApi(apiConfiguration);
      const [username, tag] = inputValue.split("#");
      gameApi.playerList({ username, tag }).then(setSearchResults);
    }
  }, [inputValue]);

  return (
    <div
      className={className}
      style={{ position: "relative" }}
      onSelect={() => setShowResults(true)}
      onBlur={(e) => {
        if (!e.currentTarget.contains(e.relatedTarget)) setShowResults(false);
      }}
    >
      <input
        type="text"
        required
        className="form-control player-search-input"
        placeholder="username#tag"
        value={inputValue}
        onChange={(e) => {
          setInputValue(e.target.value);
        }}
        disabled={disabled}
        style={{
          borderBottomLeftRadius: visibleResults ? "0px" : "",
          borderBottomRightRadius: visibleResults ? "0px" : "",
          borderBottomWidth: visibleResults ? "0px" : "",
        }}
      />
      {visibleResults && (
        <div
          className="w-100 bg-white card position-absolute"
          style={{
            borderRadius: "0px 0px 4px 4px",
            overflowX: "hidden",
            overflowY: "scroll",
            zIndex: "20000",
          }}
        >
          {searchResults.length > 0 ? (
            searchResults.map((result) => {
              const content = (
                <>
                  <PlayerCardImage
                    className="rounded me-2"
                    style={{ maxHeight: "24px", maxWidth: "24px" }}
                    cardId={result.card}
                  />
                  <div
                    className="text-muted"
                    style={{
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <span style={{ color: "var(--bs-body-color)" }}>
                      {result.username}
                    </span>
                    #{result.tag}
                  </div>
                </>
              );
              return isLink ? (
                <Link
                  key={result.puuid}
                  to={`/user/${encodeURIComponent(result.username)}/${
                    result.tag
                  }/`}
                  className="btn btn-search rounded-0 d-flex flex-row p-1 b-0"
                >
                  {content}
                </Link>
              ) : (
                <button
                  key={result.puuid}
                  type="button"
                  className="btn btn-search rounded-0 d-flex flex-row p-1 b-0"
                  onClick={() =>
                    onClick && onClick(result, () => setShowResults(false))
                  }
                >
                  {content}
                </button>
              );
            })
          ) : (
            <div className="text-muted py-1 px-2">No results found...</div>
          )}
        </div>
      )}
    </div>
  );
}
