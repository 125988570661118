/* tslint:disable */
/* eslint-disable */
/**
 * ValData API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CharacterStatResponse,
    CharacterStatResponseFromJSON,
    CharacterStatResponseFromJSONTyped,
    CharacterStatResponseToJSON,
} from './CharacterStatResponse';

/**
 * 
 * @export
 * @interface CharacterStatsResponse
 */
export interface CharacterStatsResponse {
    /**
     * 
     * @type {number}
     * @memberof CharacterStatsResponse
     */
    total: number;
    /**
     * 
     * @type {Array<CharacterStatResponse>}
     * @memberof CharacterStatsResponse
     */
    stats: Array<CharacterStatResponse>;
}

export function CharacterStatsResponseFromJSON(json: any): CharacterStatsResponse {
    return CharacterStatsResponseFromJSONTyped(json, false);
}

export function CharacterStatsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CharacterStatsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'total': json['total'],
        'stats': ((json['stats'] as Array<any>).map(CharacterStatResponseFromJSON)),
    };
}

export function CharacterStatsResponseToJSON(value?: CharacterStatsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'total': value.total,
        'stats': ((value.stats as Array<any>).map(CharacterStatResponseToJSON)),
    };
}

