/* tslint:disable */
/* eslint-disable */
/**
 * ValData API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface KillOrders
 */
export interface KillOrders {
    /**
     * 
     * @type {number}
     * @memberof KillOrders
     */
    count1: number;
    /**
     * 
     * @type {number}
     * @memberof KillOrders
     */
    count2: number;
    /**
     * 
     * @type {number}
     * @memberof KillOrders
     */
    count3: number;
    /**
     * 
     * @type {number}
     * @memberof KillOrders
     */
    count4: number;
    /**
     * 
     * @type {number}
     * @memberof KillOrders
     */
    count5: number;
}

export function KillOrdersFromJSON(json: any): KillOrders {
    return KillOrdersFromJSONTyped(json, false);
}

export function KillOrdersFromJSONTyped(json: any, ignoreDiscriminator: boolean): KillOrders {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count1': json['count_1'],
        'count2': json['count_2'],
        'count3': json['count_3'],
        'count4': json['count_4'],
        'count5': json['count_5'],
    };
}

export function KillOrdersToJSON(value?: KillOrders | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count_1': value.count1,
        'count_2': value.count2,
        'count_3': value.count3,
        'count_4': value.count4,
        'count_5': value.count5,
    };
}

