/* tslint:disable */
/* eslint-disable */
/**
 * ValData API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PlayerInMatch,
    PlayerInMatchFromJSON,
    PlayerInMatchFromJSONTyped,
    PlayerInMatchToJSON,
} from './PlayerInMatch';

/**
 * 
 * @export
 * @interface TeamMapping
 */
export interface TeamMapping {
    /**
     * 
     * @type {Array<PlayerInMatch>}
     * @memberof TeamMapping
     */
    b: Array<PlayerInMatch>;
    /**
     * 
     * @type {Array<PlayerInMatch>}
     * @memberof TeamMapping
     */
    r: Array<PlayerInMatch>;
}

export function TeamMappingFromJSON(json: any): TeamMapping {
    return TeamMappingFromJSONTyped(json, false);
}

export function TeamMappingFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeamMapping {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'b': ((json['B'] as Array<any>).map(PlayerInMatchFromJSON)),
        'r': ((json['R'] as Array<any>).map(PlayerInMatchFromJSON)),
    };
}

export function TeamMappingToJSON(value?: TeamMapping | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'B': ((value.b as Array<any>).map(PlayerInMatchToJSON)),
        'R': ((value.r as Array<any>).map(PlayerInMatchToJSON)),
    };
}

