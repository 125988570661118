import { Configuration } from "api";

const developerMode =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development";

const defaultApiConfiguration = new Configuration({
  basePath: developerMode ? "http://localhost:8000" : "https://api.valdata.app",
  accessToken: "",
  fetchApi: fetch,
});

const defaultConfig = {
  production: !developerMode,
  apiConfiguration: defaultApiConfiguration,
  locale: "en-gb",
};

export { developerMode, defaultConfig };
