/* tslint:disable */
/* eslint-disable */
/**
 * ValData API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * The base user serializer, that displays most of the user's data.
 * @export
 * @interface PatchedUserWriteRequest
 */
export interface PatchedUserWriteRequest {
    /**
     * 
     * @type {string}
     * @memberof PatchedUserWriteRequest
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedUserWriteRequest
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedUserWriteRequest
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedUserWriteRequest
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedUserWriteRequest
     */
    profilePictureId?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof PatchedUserWriteRequest
     */
    linkedPlayers?: Array<string>;
}

export function PatchedUserWriteRequestFromJSON(json: any): PatchedUserWriteRequest {
    return PatchedUserWriteRequestFromJSONTyped(json, false);
}

export function PatchedUserWriteRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedUserWriteRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'username': !exists(json, 'username') ? undefined : json['username'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'profilePictureId': !exists(json, 'profile_picture_id') ? undefined : json['profile_picture_id'],
        'linkedPlayers': !exists(json, 'linked_players') ? undefined : json['linked_players'],
    };
}

export function PatchedUserWriteRequestToJSON(value?: PatchedUserWriteRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'username': value.username,
        'email': value.email,
        'password': value.password,
        'description': value.description,
        'profile_picture_id': value.profilePictureId,
        'linked_players': value.linkedPlayers,
    };
}

