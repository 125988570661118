import { defaultConfig } from "defaultConfig";
import React, { useContext, useState } from "react";

import { TokenApi, TokenObtainPair, TokenRefresh, User, UsersApi } from "api";

interface AuthContextType {
  getAccess: () => string;
  getRefresh: () => string;
  signup: (username: string, email: string, password: string) => Promise<User>;
  login: (username: string, password: string) => Promise<void>;
  logout: () => void;
  refreshToken: () => Promise<void>;
  setToken: (token: TokenRefresh | TokenObtainPair) => void;
  isAuthenticated: boolean;
  isCreated: boolean;
}

const AuthContext = React.createContext<AuthContextType>(null!);

/**
 * Uses AuthContext to provide auth state to children
 * @returns {React.Context<AuthContextType>}
 */
function useAuth() {
  return useContext(AuthContext);
}

interface ProvideAuthProps {
  children?: React.ReactNode;
}

/**
 * Provides auth state and methods to children
 * @param props
 * @returns {React.Provider<AuthContextType>}
 */
function AuthProvider({ children }: ProvideAuthProps) {
  const getAccess = () => localStorage.getItem("access") || "";
  const getRefresh = () => localStorage.getItem("refresh") || "";

  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(
    getAccess() !== ""
  );
  const [isCreated, setIsCreated] = useState<boolean>(false);

  const defaultApiConfiguration = defaultConfig.apiConfiguration;
  const tokenApi = new TokenApi(defaultApiConfiguration);
  const usersApi = new UsersApi(defaultApiConfiguration);

  const setToken = (token: TokenRefresh | TokenObtainPair) => {
    localStorage.setItem("access", token.access);
    if ("refresh" in token) {
      localStorage.setItem("refresh", token.refresh);
    }
    setIsAuthenticated(true);
  };

  const login = async (username: string, password: string): Promise<void> =>
    tokenApi
      .tokenCreate({
        tokenObtainPairRequest: {
          username,
          password,
        },
      })
      .then((token) => {
        setToken(token);
        return Promise.resolve();
      })
      .catch((error) => Promise.reject(error));

  const signup = async (
    username: string,
    email: string,
    password: string
  ): Promise<User> =>
    usersApi
      .usersCreate({
        userWriteRequest: {
          username,
          email,
          password,
          linkedPlayers: [],
        },
      })
      .then((createdUser) => {
        setIsCreated(true);
        return login(username, password).then(() => createdUser);
      })
      .catch((error) => Promise.reject(error));

  /**
   * Logout the user
   */
  const logout = () => {
    localStorage.removeItem("access");
    localStorage.removeItem("refresh");
    setIsAuthenticated(false);
  };

  /**
   * Refreshes the token of the user
   */
  const refreshToken = async (): Promise<void> =>
    tokenApi
      .tokenRefreshCreate({
        tokenRefreshRequest: {
          refresh: getRefresh(),
        },
      })
      .then((token) => {
        setToken(token);
      })
      .catch((error) => {
        logout();
        throw error;
      });

  const value = React.useMemo(
    () => ({
      getAccess,
      getRefresh,
      signup,
      login,
      logout,
      refreshToken,
      setToken,
      isAuthenticated,
      isCreated,
    }),
    [isAuthenticated, isCreated]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export { AuthProvider, AuthContextType, useAuth };
