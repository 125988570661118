/* tslint:disable */
/* eslint-disable */
/**
 * ValData API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserMapStats
 */
export interface UserMapStats {
    /**
     * 
     * @type {string}
     * @memberof UserMapStats
     */
    puuid: string;
    /**
     * 
     * @type {number}
     * @memberof UserMapStats
     */
    score: number;
    /**
     * 
     * @type {number}
     * @memberof UserMapStats
     */
    damageDealt: number;
    /**
     * 
     * @type {number}
     * @memberof UserMapStats
     */
    kills: number;
    /**
     * 
     * @type {number}
     * @memberof UserMapStats
     */
    deaths: number;
    /**
     * 
     * @type {number}
     * @memberof UserMapStats
     */
    assists: number;
}

export function UserMapStatsFromJSON(json: any): UserMapStats {
    return UserMapStatsFromJSONTyped(json, false);
}

export function UserMapStatsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserMapStats {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'puuid': json['puuid'],
        'score': json['score'],
        'damageDealt': json['damage_dealt'],
        'kills': json['kills'],
        'deaths': json['deaths'],
        'assists': json['assists'],
    };
}

export function UserMapStatsToJSON(value?: UserMapStats | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'puuid': value.puuid,
        'score': value.score,
        'damage_dealt': value.damageDealt,
        'kills': value.kills,
        'deaths': value.deaths,
        'assists': value.assists,
    };
}

