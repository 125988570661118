/* tslint:disable */
/* eslint-disable */
/**
 * ValData API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Player,
    PlayerFromJSON,
    PlayerFromJSONTyped,
    PlayerToJSON,
} from './Player';
import {
    TeamEnum,
    TeamEnumFromJSON,
    TeamEnumFromJSONTyped,
    TeamEnumToJSON,
} from './TeamEnum';

/**
 * 
 * @export
 * @interface PlayerInMatch
 */
export interface PlayerInMatch {
    /**
     * 
     * @type {number}
     * @memberof PlayerInMatch
     */
    readonly id: number;
    /**
     * 
     * @type {Player}
     * @memberof PlayerInMatch
     */
    player: Player;
    /**
     * 
     * @type {TeamEnum}
     * @memberof PlayerInMatch
     */
    team: TeamEnum;
    /**
     * 
     * @type {string}
     * @memberof PlayerInMatch
     */
    character: string;
    /**
     * 
     * @type {number}
     * @memberof PlayerInMatch
     */
    cCasts: number;
    /**
     * 
     * @type {number}
     * @memberof PlayerInMatch
     */
    qCasts: number;
    /**
     * 
     * @type {number}
     * @memberof PlayerInMatch
     */
    eCasts: number;
    /**
     * 
     * @type {number}
     * @memberof PlayerInMatch
     */
    xCasts: number;
    /**
     * 
     * @type {number}
     * @memberof PlayerInMatch
     */
    score: number;
    /**
     * 
     * @type {number}
     * @memberof PlayerInMatch
     */
    kills: number;
    /**
     * 
     * @type {number}
     * @memberof PlayerInMatch
     */
    deaths: number;
    /**
     * 
     * @type {number}
     * @memberof PlayerInMatch
     */
    assists: number;
    /**
     * 
     * @type {number}
     * @memberof PlayerInMatch
     */
    damageDealt: number;
}

export function PlayerInMatchFromJSON(json: any): PlayerInMatch {
    return PlayerInMatchFromJSONTyped(json, false);
}

export function PlayerInMatchFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlayerInMatch {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'player': PlayerFromJSON(json['player']),
        'team': TeamEnumFromJSON(json['team']),
        'character': json['character'],
        'cCasts': json['c_casts'],
        'qCasts': json['q_casts'],
        'eCasts': json['e_casts'],
        'xCasts': json['x_casts'],
        'score': json['score'],
        'kills': json['kills'],
        'deaths': json['deaths'],
        'assists': json['assists'],
        'damageDealt': json['damage_dealt'],
    };
}

export function PlayerInMatchToJSON(value?: PlayerInMatch | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'player': PlayerToJSON(value.player),
        'team': TeamEnumToJSON(value.team),
        'character': value.character,
        'c_casts': value.cCasts,
        'q_casts': value.qCasts,
        'e_casts': value.eCasts,
        'x_casts': value.xCasts,
        'score': value.score,
        'kills': value.kills,
        'deaths': value.deaths,
        'assists': value.assists,
        'damage_dealt': value.damageDealt,
    };
}

