import { Client } from "@valapi/valorant-api.com";
import React, { useContext, useMemo } from "react";

const ConfigContext = React.createContext<Client>(
  new Client({ language: "en-US" })
);

function useValClient() {
  return useContext(ConfigContext);
}

function ValorantClientProvider({ children }: { children?: React.ReactNode }) {
  const client = useMemo(() => new Client({ language: "en-US" }), []);
  return (
    <ConfigContext.Provider value={client}>{children}</ConfigContext.Provider>
  );
}

export { ValorantClientProvider, useValClient };
