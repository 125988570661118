/* tslint:disable */
/* eslint-disable */
/**
 * ValData API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ResultEnum {
    Both = 'both',
    Loss = 'loss',
    Win = 'win'
}

export function ResultEnumFromJSON(json: any): ResultEnum {
    return ResultEnumFromJSONTyped(json, false);
}

export function ResultEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResultEnum {
    return json as ResultEnum;
}

export function ResultEnumToJSON(value?: ResultEnum | null): any {
    return value as any;
}

