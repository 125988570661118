/* tslint:disable */
/* eslint-disable */
/**
 * ValData API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BasicUser,
    BasicUserFromJSON,
    BasicUserFromJSONTyped,
    BasicUserToJSON,
} from './BasicUser';
import {
    Player,
    PlayerFromJSON,
    PlayerFromJSONTyped,
    PlayerToJSON,
} from './Player';

/**
 * 
 * @export
 * @interface MatchGroupRelative
 */
export interface MatchGroupRelative {
    /**
     * 
     * @type {number}
     * @memberof MatchGroupRelative
     */
    readonly id: number;
    /**
     * 
     * @type {BasicUser}
     * @memberof MatchGroupRelative
     */
    readonly creator: BasicUser | null;
    /**
     * 
     * @type {number}
     * @memberof MatchGroupRelative
     */
    readonly matchCount: number;
    /**
     * 
     * @type {Array<Player>}
     * @memberof MatchGroupRelative
     */
    linkedPlayers?: Array<Player>;
    /**
     * 
     * @type {boolean}
     * @memberof MatchGroupRelative
     */
    readonly matchInGroup: boolean;
    /**
     * 
     * @type {string}
     * @memberof MatchGroupRelative
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof MatchGroupRelative
     */
    imageId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MatchGroupRelative
     */
    automaticLinking?: boolean;
}

export function MatchGroupRelativeFromJSON(json: any): MatchGroupRelative {
    return MatchGroupRelativeFromJSONTyped(json, false);
}

export function MatchGroupRelativeFromJSONTyped(json: any, ignoreDiscriminator: boolean): MatchGroupRelative {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'creator': BasicUserFromJSON(json['creator']),
        'matchCount': json['match_count'],
        'linkedPlayers': !exists(json, 'linked_players') ? undefined : ((json['linked_players'] as Array<any>).map(PlayerFromJSON)),
        'matchInGroup': json['match_in_group'],
        'name': json['name'],
        'imageId': !exists(json, 'image_id') ? undefined : json['image_id'],
        'automaticLinking': !exists(json, 'automatic_linking') ? undefined : json['automatic_linking'],
    };
}

export function MatchGroupRelativeToJSON(value?: MatchGroupRelative | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'linked_players': value.linkedPlayers === undefined ? undefined : ((value.linkedPlayers as Array<any>).map(PlayerToJSON)),
        'name': value.name,
        'image_id': value.imageId,
        'automatic_linking': value.automaticLinking,
    };
}

