import useDarkMode from "@fisch0920/use-dark-mode";
import TimeAgo from "javascript-time-ago";
import React, { useRef } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Slide, ToastContainer } from "react-toastify";

import { AuthProvider } from "providers/AuthProvider";
import { ConfigProvider } from "providers/ConfigProvider";
import { CurrentUserProvider } from "providers/CurrentUserProvider";
import { ValorantClientProvider } from "providers/ValorantApiClientProvider";
import { ValorantApiProvider } from "providers/ValorantApiProvider";

import Compose from "components/Compose";
import Routes from "components/Router";

const en = require("javascript-time-ago/locale/en.json");

TimeAgo.addDefaultLocale(en);

function App() {
  const parentRef = useRef<HTMLElement>(null);
  useDarkMode();

  return (
    <div>
      <Compose
        components={[
          ValorantClientProvider,
          ValorantApiProvider,
          AuthProvider,
          ConfigProvider,
          CurrentUserProvider,
          Router,
        ]}
      >
        <main ref={parentRef} className="d-flex flex-column">
          <React.Suspense fallback={null}>
            <Routes />
          </React.Suspense>
          <ToastContainer transition={Slide} hideProgressBar />
        </main>
      </Compose>
    </div>
  );
}

export default App;
