import React, { lazy } from "react";
import { Route, Routes as RouterRoutes } from "react-router-dom";

import Footer from "./Footer";
import ValdataNavbar from "./Navbar";

const Welcome = lazy(() => import("pages/Welcome"));
const LoginPage = lazy(() => import("pages/LoginPage"));
const AccountPage = lazy(() => import("pages/AccountPage"));
const UserPage = lazy(() => import("pages/UserPage"));
const UserMapStatPage = lazy(() => import("pages/UserMapStatPage"));
const AdvancedStatPage = lazy(() => import("pages/AdvancedStatPage"));

const TeamPage = lazy(() => import("pages/TeamPage"));
const TeamMapStatPage = lazy(() => import("pages/TeamMapStatPage"));

const GroupPage = lazy(() => import("pages/GroupPage"));
const GroupMapStatPage = lazy(() => import("pages/GroupMapStatPage"));

const NotFound = lazy(() => import("pages/NotFound"));

function Layout({
  element,
  noFooter,
  noNavbar,
}: {
  element: React.ReactNode;
  noFooter?: boolean;
  noNavbar?: boolean;
}) {
  return (
    <>
      <div className="container-fluid p-0 flex-grow-1 h-auto d-flex flex-column">
        {!noNavbar && <ValdataNavbar />}
        {element}
      </div>
      {!noFooter && <Footer />}
    </>
  );
}

function Routes() {
  return (
    <RouterRoutes>
      <Route path="/" element={<Layout element={<Welcome />} />} />
      <Route
        path="account"
        element={<Layout element={<AccountPage />} noFooter />}
      >
        <Route
          path=":username"
          element={<Layout element={<AccountPage />} noFooter />}
        />
      </Route>
      <Route
        path="login"
        element={<Layout element={<LoginPage />} noFooter />}
      />

      <Route path="user/:username/:tag">
        <Route index element={<Layout element={<UserPage />} />} />
        <Route path="stats/:map">
          <Route index element={<Layout element={<UserMapStatPage />} />} />
        </Route>
        <Route path="advanced">
          <Route index element={<Layout element={<AdvancedStatPage />} />} />
        </Route>
      </Route>

      <Route path="team/:teamId">
        <Route index element={<Layout element={<TeamPage />} />} />
        <Route path="stats/:map">
          <Route index element={<Layout element={<TeamMapStatPage />} />} />
        </Route>
      </Route>

      <Route path="group/:groupId">
        <Route index element={<Layout element={<GroupPage />} />} />
        <Route path="stats/:map">
          <Route index element={<Layout element={<GroupMapStatPage />} />} />
        </Route>
      </Route>
      <Route path="*" element={<Layout element={<NotFound />} />} />
    </RouterRoutes>
  );
}

export default Routes;
