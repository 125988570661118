/* tslint:disable */
/* eslint-disable */
/**
 * ValData API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Point,
    PointFromJSON,
    PointFromJSONTyped,
    PointToJSON,
} from './Point';

/**
 * 
 * @export
 * @interface Line
 */
export interface Line {
    /**
     * 
     * @type {Point}
     * @memberof Line
     */
    shooter: Point;
    /**
     * 
     * @type {Point}
     * @memberof Line
     */
    victim: Point;
}

export function LineFromJSON(json: any): Line {
    return LineFromJSONTyped(json, false);
}

export function LineFromJSONTyped(json: any, ignoreDiscriminator: boolean): Line {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shooter': PointFromJSON(json['shooter']),
        'victim': PointFromJSON(json['victim']),
    };
}

export function LineToJSON(value?: Line | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shooter': PointToJSON(value.shooter),
        'victim': PointToJSON(value.victim),
    };
}

