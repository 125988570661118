/* tslint:disable */
/* eslint-disable */
/**
 * ValData API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PatchedTeamWriteRequest
 */
export interface PatchedTeamWriteRequest {
    /**
     * 
     * @type {string}
     * @memberof PatchedTeamWriteRequest
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedTeamWriteRequest
     */
    imageId?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof PatchedTeamWriteRequest
     */
    players?: Array<string>;
}

export function PatchedTeamWriteRequestFromJSON(json: any): PatchedTeamWriteRequest {
    return PatchedTeamWriteRequestFromJSONTyped(json, false);
}

export function PatchedTeamWriteRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedTeamWriteRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'imageId': !exists(json, 'image_id') ? undefined : json['image_id'],
        'players': !exists(json, 'players') ? undefined : json['players'],
    };
}

export function PatchedTeamWriteRequestToJSON(value?: PatchedTeamWriteRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'image_id': value.imageId,
        'players': value.players,
    };
}

