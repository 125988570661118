import "./Footer.css";

function Footer({ className }: { className?: string }) {
  return (
    <div className={`w-100 d-flex flex-column ${className ?? ""}`}>
      <footer className="text-muted">
        <div>
          Valdata isn&apos;t endorsed by Riot Games and doesn&apos;t reflect the
          views or opinions of Riot Games or anyone officially involved in
          producing or managing Valorant. Valorant and Riot Games are trademarks
          or registered trademarks of Riot Games, Inc. Valorant © Riot Games
        </div>
        <div className="pt-3">© 2022 ValData</div>
      </footer>
    </div>
  );
}

export default Footer;
