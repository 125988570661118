import { Client } from "@valapi/valorant-api.com";
import { ValAPIClient } from "@valapi/valorant-api.com/dist/client/Client";
import { Agents } from "@valapi/valorant-api.com/dist/service/Agents";
import { Buddies } from "@valapi/valorant-api.com/dist/service/Buddies";
import { Bundles } from "@valapi/valorant-api.com/dist/service/Bundles";
import { Ceremonies } from "@valapi/valorant-api.com/dist/service/Ceremonies";
import { CompetitiveTiers } from "@valapi/valorant-api.com/dist/service/CompetitiveTiers";
import { ContentTiers } from "@valapi/valorant-api.com/dist/service/ContentTiers";
import { Contracts } from "@valapi/valorant-api.com/dist/service/Contracts";
import { Currencies } from "@valapi/valorant-api.com/dist/service/Currencies";
import { Events } from "@valapi/valorant-api.com/dist/service/Events";
import { Gamemodes } from "@valapi/valorant-api.com/dist/service/Gamemodes";
import { Gear as ApiGear } from "@valapi/valorant-api.com/dist/service/Gear";
import { LevelBorders } from "@valapi/valorant-api.com/dist/service/LevelBorders";
import { Maps } from "@valapi/valorant-api.com/dist/service/Maps";
import { PlayerCards } from "@valapi/valorant-api.com/dist/service/PlayerCards";
import { PlayerTitles } from "@valapi/valorant-api.com/dist/service/PlayerTitles";
import { Seasons } from "@valapi/valorant-api.com/dist/service/Seasons";
import { Sprays } from "@valapi/valorant-api.com/dist/service/Sprays";
import { Themes } from "@valapi/valorant-api.com/dist/service/Themes";
import { Weapons } from "@valapi/valorant-api.com/dist/service/Weapons";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useState,
} from "react";

import { KeysOfType, ReplaceKeyTypes } from "helpers/Types";

import Compose from "components/Compose";

import { useValClient } from "./ValorantApiClientProvider";

type KeyType = string | number | symbol;

type ApiCallFunc<T> = () => Promise<Client.Service<Array<T>>>;

type ValidKeys<K extends keyof Client> = KeysOfType<
  Client[K],
  ApiCallFunc<unknown>
>;

type ValService<M extends KeyType, T> = {
  [key in M]: () => Promise<Client.Service<T[]>>;
};

type TypeForService<
  K extends keyof Client,
  M extends KeyType
> = Client[K] extends ValService<M, infer U> ? U : never;

type PatchApiType<T> = ReplaceKeyTypes<
  T,
  ValAPIClient.Response<string>,
  string
>;

type ReturnTypeForService<
  K extends keyof Client,
  M extends KeyType
> = PatchApiType<TypeForService<K, M>>;

type Provider = (props: {
  children?: ReactNode;
  client: ValAPIClient;
}) => JSX.Element;

function makeProvider<K extends keyof Client, M extends ValidKeys<K>>(
  key: K,
  method: M
): [() => ReturnTypeForService<K, M>[] | undefined, Provider] {
  const ValApiContext = createContext<
    () => ReturnTypeForService<K, M>[] | undefined
  >(() => undefined);

  function useApi() {
    return useContext(ValApiContext)();
  }

  function ApiProvider({ children }: { children?: React.ReactNode }) {
    const [data, setData] = useState<ReturnTypeForService<K, M>[]>();
    let alreadyWaiting = false;
    const client = useValClient();

    const callback = useCallback(() => {
      if (alreadyWaiting) return undefined;
      if (data) return data;

      alreadyWaiting = true;
      (
        client[key][method] as unknown as ApiCallFunc<
          ReturnTypeForService<K, M>
        >
      )().then((d) => setData(d.data?.data));
      return undefined;
    }, [data, setData]);

    return (
      <ValApiContext.Provider value={callback}>
        {children}
      </ValApiContext.Provider>
    );
  }

  return [useApi, ApiProvider];
}

const [useAgents, AgentsProvider] = makeProvider("Agents", "get");
const [useBuddies, BuddiesProvider] = makeProvider("Buddies", "get");
const [useBundles, BundlesProvider] = makeProvider("Bundles", "get");
const [useCeremonies, CeremoniesProvider] = makeProvider("Ceremonies", "get");
const [useCompetitiveSeasons, CompetitiveSeasonsProvider] = makeProvider(
  "Seasons",
  "getCompetitiveSeasons"
);
const [useCompetitiveTiers, CompetitiveTiersProvider] = makeProvider(
  "CompetitiveTiers",
  "get"
);
const [useContentTiers, ContentTiersProvider] = makeProvider(
  "ContentTiers",
  "get"
);
const [useContracts, ContractsProvider] = makeProvider("Contracts", "get");
const [useCurrencies, CurrenciesProvider] = makeProvider("Currencies", "get");
const [useEvents, EventsProvider] = makeProvider("Events", "get");
const [useGamemodes, GamemodesProvider] = makeProvider("Gamemodes", "get");
const [useGear, GearProvider] = makeProvider("Gear", "get");
const [useLevelBorders, LevelBordersProvider] = makeProvider(
  "LevelBorders",
  "get"
);
const [useMaps, MapProvider] = makeProvider("Maps", "get");
const [usePlayerCards, PlayerCardsProvider] = makeProvider(
  "PlayerCards",
  "get"
);
const [usePlayerTitles, PlayerTitlesProvider] = makeProvider(
  "PlayerTitles",
  "get"
);
const [useSeasons, SeasonsProvider] = makeProvider("Seasons", "get");
const [useSprays, SpraysProvider] = makeProvider("Sprays", "get");
const [useThemes, ThemesProvider] = makeProvider("Themes", "get");
const [useWeapons, WeaponsProvider] = makeProvider("Weapons", "get");

function ValorantApiProvider({ children }: { children: ReactNode }) {
  return (
    <Compose
      components={[
        AgentsProvider,
        BuddiesProvider,
        BundlesProvider,
        CeremoniesProvider,
        CompetitiveSeasonsProvider,
        CompetitiveTiersProvider,
        ContentTiersProvider,
        ContractsProvider,
        CurrenciesProvider,
        EventsProvider,
        GamemodesProvider,
        GearProvider,
        LevelBordersProvider,
        MapProvider,
        PlayerCardsProvider,
        PlayerTitlesProvider,
        SeasonsProvider,
        SpraysProvider,
        ThemesProvider,
        WeaponsProvider,
      ]}
    >
      {children}
    </Compose>
  );
}

export type Agent = PatchApiType<Agents.Agents>;
export type Buddy = PatchApiType<Buddies.Buddies>;
export type Bundle = PatchApiType<Bundles.Bundles>;
export type Ceremony = PatchApiType<Ceremonies.Ceremonies>;
export type CompetitiveSeason = PatchApiType<Seasons.CompetitiveSeasons>;
export type CompetitiveTier = PatchApiType<CompetitiveTiers.CompetitiveTiers>;
export type ContentTier = PatchApiType<ContentTiers.ContentTiers>;
export type Contract = PatchApiType<Contracts.Contracts>;
export type Currency = PatchApiType<Currencies.Currencies>;
export type Event = PatchApiType<Events.Events>;
export type Gamemode = PatchApiType<Gamemodes.Gamemodes>;
export type Gear = PatchApiType<ApiGear.Gear>;
export type LevelBorder = PatchApiType<LevelBorders.LevelBorders>;
export type Map = PatchApiType<Maps.Maps>;
export type PlayerCard = PatchApiType<PlayerCards.PlayerCards>;
export type PlayerTitle = PatchApiType<PlayerTitles.PlayerTitles>;
export type Season = PatchApiType<Seasons.Seasons>;
export type Spray = PatchApiType<Sprays.Sprays>;
export type Theme = PatchApiType<Themes.Themes>;
export type Weapon = PatchApiType<Weapons.Weapons>;

export {
  useAgents,
  useBuddies,
  useBundles,
  useCeremonies,
  useCompetitiveSeasons,
  useCompetitiveTiers,
  useContentTiers,
  useContracts,
  useCurrencies,
  useEvents,
  useGamemodes,
  useGear,
  useLevelBorders,
  useMaps,
  usePlayerCards,
  usePlayerTitles,
  useSeasons,
  useSprays,
  useThemes,
  useWeapons,
  ValorantApiProvider,
};
