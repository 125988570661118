/* tslint:disable */
/* eslint-disable */
/**
 * ValData API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MatchGroupWriteRequest
 */
export interface MatchGroupWriteRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof MatchGroupWriteRequest
     */
    matches?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof MatchGroupWriteRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof MatchGroupWriteRequest
     */
    imageId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MatchGroupWriteRequest
     */
    automaticLinking?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof MatchGroupWriteRequest
     */
    linkedPlayers: Array<string>;
}

export function MatchGroupWriteRequestFromJSON(json: any): MatchGroupWriteRequest {
    return MatchGroupWriteRequestFromJSONTyped(json, false);
}

export function MatchGroupWriteRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): MatchGroupWriteRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'matches': !exists(json, 'matches') ? undefined : json['matches'],
        'name': json['name'],
        'imageId': !exists(json, 'image_id') ? undefined : json['image_id'],
        'automaticLinking': !exists(json, 'automatic_linking') ? undefined : json['automatic_linking'],
        'linkedPlayers': json['linked_players'],
    };
}

export function MatchGroupWriteRequestToJSON(value?: MatchGroupWriteRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'matches': value.matches,
        'name': value.name,
        'image_id': value.imageId,
        'automatic_linking': value.automaticLinking,
        'linked_players': value.linkedPlayers,
    };
}

