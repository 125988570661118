/* tslint:disable */
/* eslint-disable */
/**
 * ValData API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PatchedMatchGroupWriteRequest
 */
export interface PatchedMatchGroupWriteRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof PatchedMatchGroupWriteRequest
     */
    matches?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PatchedMatchGroupWriteRequest
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchedMatchGroupWriteRequest
     */
    imageId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PatchedMatchGroupWriteRequest
     */
    automaticLinking?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof PatchedMatchGroupWriteRequest
     */
    linkedPlayers?: Array<string>;
}

export function PatchedMatchGroupWriteRequestFromJSON(json: any): PatchedMatchGroupWriteRequest {
    return PatchedMatchGroupWriteRequestFromJSONTyped(json, false);
}

export function PatchedMatchGroupWriteRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchedMatchGroupWriteRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'matches': !exists(json, 'matches') ? undefined : json['matches'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'imageId': !exists(json, 'image_id') ? undefined : json['image_id'],
        'automaticLinking': !exists(json, 'automatic_linking') ? undefined : json['automatic_linking'],
        'linkedPlayers': !exists(json, 'linked_players') ? undefined : json['linked_players'],
    };
}

export function PatchedMatchGroupWriteRequestToJSON(value?: PatchedMatchGroupWriteRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'matches': value.matches,
        'name': value.name,
        'image_id': value.imageId,
        'automatic_linking': value.automaticLinking,
        'linked_players': value.linkedPlayers,
    };
}

