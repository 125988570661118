/* tslint:disable */
/* eslint-disable */
/**
 * ValData API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Match,
    MatchFromJSON,
    MatchFromJSONTyped,
    MatchToJSON,
} from './Match';

/**
 * 
 * @export
 * @interface RecentMatchesResponse
 */
export interface RecentMatchesResponse {
    /**
     * 
     * @type {Array<Match>}
     * @memberof RecentMatchesResponse
     */
    matches: Array<Match>;
    /**
     * 
     * @type {number}
     * @memberof RecentMatchesResponse
     */
    count: number;
}

export function RecentMatchesResponseFromJSON(json: any): RecentMatchesResponse {
    return RecentMatchesResponseFromJSONTyped(json, false);
}

export function RecentMatchesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecentMatchesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'matches': ((json['matches'] as Array<any>).map(MatchFromJSON)),
        'count': json['count'],
    };
}

export function RecentMatchesResponseToJSON(value?: RecentMatchesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'matches': ((value.matches as Array<any>).map(MatchToJSON)),
        'count': value.count,
    };
}

