/* tslint:disable */
/* eslint-disable */
/**
 * ValData API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AdvancedStatResponse,
    AdvancedStatResponseFromJSON,
    AdvancedStatResponseToJSON,
    CharacterStatsResponse,
    CharacterStatsResponseFromJSON,
    CharacterStatsResponseToJSON,
    ModeEnum,
    ModeEnumFromJSON,
    ModeEnumToJSON,
    Player,
    PlayerFromJSON,
    PlayerToJSON,
    RecentMatchesResponse,
    RecentMatchesResponseFromJSON,
    RecentMatchesResponseToJSON,
    RefreshResponse,
    RefreshResponseFromJSON,
    RefreshResponseToJSON,
    ResultEnum,
    ResultEnumFromJSON,
    ResultEnumToJSON,
    SelectEnum,
    SelectEnumFromJSON,
    SelectEnumToJSON,
    SideEnum,
    SideEnumFromJSON,
    SideEnumToJSON,
} from '../models';

export interface GameAdvancedStatsRetrieveRequest {
    puuid: string;
    agent?: string;
    map?: string;
    mode?: ModeEnum;
    result?: ResultEnum;
    select?: SelectEnum;
    selectParam?: string;
    side?: SideEnum;
}

export interface GameCharactersRetrieveRequest {
    puuid: string;
    mode?: ModeEnum;
}

export interface GameRecentRetrieveRequest {
    puuid: string;
    mode?: ModeEnum;
    offset?: number;
}

export interface GameRefreshUpdateRequest {
    puuid: string;
    mode?: ModeEnum;
}

export interface PlayerCreateRequest {
    tag: string;
    username: string;
}

export interface PlayerListRequest {
    tag?: string;
    username?: string;
}

/**
 * 
 */
export class GameApi extends runtime.BaseAPI {

    /**
     */
    async gameAdvancedStatsRetrieveRaw(requestParameters: GameAdvancedStatsRetrieveRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AdvancedStatResponse>> {
        if (requestParameters.puuid === null || requestParameters.puuid === undefined) {
            throw new runtime.RequiredError('puuid','Required parameter requestParameters.puuid was null or undefined when calling gameAdvancedStatsRetrieve.');
        }

        const queryParameters: any = {};

        if (requestParameters.agent !== undefined) {
            queryParameters['agent'] = requestParameters.agent;
        }

        if (requestParameters.map !== undefined) {
            queryParameters['map'] = requestParameters.map;
        }

        if (requestParameters.mode !== undefined) {
            queryParameters['mode'] = requestParameters.mode;
        }

        if (requestParameters.result !== undefined) {
            queryParameters['result'] = requestParameters.result;
        }

        if (requestParameters.select !== undefined) {
            queryParameters['select'] = requestParameters.select;
        }

        if (requestParameters.selectParam !== undefined) {
            queryParameters['select_param'] = requestParameters.selectParam;
        }

        if (requestParameters.side !== undefined) {
            queryParameters['side'] = requestParameters.side;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/game/{puuid}/advanced_stats/`.replace(`{${"puuid"}}`, encodeURIComponent(String(requestParameters.puuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AdvancedStatResponseFromJSON(jsonValue));
    }

    /**
     */
    async gameAdvancedStatsRetrieve(requestParameters: GameAdvancedStatsRetrieveRequest, initOverrides?: RequestInit): Promise<AdvancedStatResponse> {
        const response = await this.gameAdvancedStatsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async gameCharactersRetrieveRaw(requestParameters: GameCharactersRetrieveRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CharacterStatsResponse>> {
        if (requestParameters.puuid === null || requestParameters.puuid === undefined) {
            throw new runtime.RequiredError('puuid','Required parameter requestParameters.puuid was null or undefined when calling gameCharactersRetrieve.');
        }

        const queryParameters: any = {};

        if (requestParameters.mode !== undefined) {
            queryParameters['mode'] = requestParameters.mode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/game/{puuid}/characters/`.replace(`{${"puuid"}}`, encodeURIComponent(String(requestParameters.puuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CharacterStatsResponseFromJSON(jsonValue));
    }

    /**
     */
    async gameCharactersRetrieve(requestParameters: GameCharactersRetrieveRequest, initOverrides?: RequestInit): Promise<CharacterStatsResponse> {
        const response = await this.gameCharactersRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async gameRecentRetrieveRaw(requestParameters: GameRecentRetrieveRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RecentMatchesResponse>> {
        if (requestParameters.puuid === null || requestParameters.puuid === undefined) {
            throw new runtime.RequiredError('puuid','Required parameter requestParameters.puuid was null or undefined when calling gameRecentRetrieve.');
        }

        const queryParameters: any = {};

        if (requestParameters.mode !== undefined) {
            queryParameters['mode'] = requestParameters.mode;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/game/{puuid}/recent/`.replace(`{${"puuid"}}`, encodeURIComponent(String(requestParameters.puuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RecentMatchesResponseFromJSON(jsonValue));
    }

    /**
     */
    async gameRecentRetrieve(requestParameters: GameRecentRetrieveRequest, initOverrides?: RequestInit): Promise<RecentMatchesResponse> {
        const response = await this.gameRecentRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async gameRefreshUpdateRaw(requestParameters: GameRefreshUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RefreshResponse>> {
        if (requestParameters.puuid === null || requestParameters.puuid === undefined) {
            throw new runtime.RequiredError('puuid','Required parameter requestParameters.puuid was null or undefined when calling gameRefreshUpdate.');
        }

        const queryParameters: any = {};

        if (requestParameters.mode !== undefined) {
            queryParameters['mode'] = requestParameters.mode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/game/{puuid}/refresh/`.replace(`{${"puuid"}}`, encodeURIComponent(String(requestParameters.puuid))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RefreshResponseFromJSON(jsonValue));
    }

    /**
     */
    async gameRefreshUpdate(requestParameters: GameRefreshUpdateRequest, initOverrides?: RequestInit): Promise<RefreshResponse> {
        const response = await this.gameRefreshUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async playerCreateRaw(requestParameters: PlayerCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Player>> {
        if (requestParameters.tag === null || requestParameters.tag === undefined) {
            throw new runtime.RequiredError('tag','Required parameter requestParameters.tag was null or undefined when calling playerCreate.');
        }

        if (requestParameters.username === null || requestParameters.username === undefined) {
            throw new runtime.RequiredError('username','Required parameter requestParameters.username was null or undefined when calling playerCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/player/{username}/{tag}/`.replace(`{${"tag"}}`, encodeURIComponent(String(requestParameters.tag))).replace(`{${"username"}}`, encodeURIComponent(String(requestParameters.username))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PlayerFromJSON(jsonValue));
    }

    /**
     */
    async playerCreate(requestParameters: PlayerCreateRequest, initOverrides?: RequestInit): Promise<Player> {
        const response = await this.playerCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async playerListRaw(requestParameters: PlayerListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Player>>> {
        const queryParameters: any = {};

        if (requestParameters.tag !== undefined) {
            queryParameters['tag'] = requestParameters.tag;
        }

        if (requestParameters.username !== undefined) {
            queryParameters['username'] = requestParameters.username;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/player/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PlayerFromJSON));
    }

    /**
     */
    async playerList(requestParameters: PlayerListRequest, initOverrides?: RequestInit): Promise<Array<Player>> {
        const response = await this.playerListRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
