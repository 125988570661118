/* tslint:disable */
/* eslint-disable */
/**
 * ValData API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    MatchGroup,
    MatchGroupFromJSON,
    MatchGroupToJSON,
    MatchGroupRelative,
    MatchGroupRelativeFromJSON,
    MatchGroupRelativeToJSON,
    MatchGroupWriteRequest,
    MatchGroupWriteRequestFromJSON,
    MatchGroupWriteRequestToJSON,
    PatchedMatchGroupWriteRequest,
    PatchedMatchGroupWriteRequestFromJSON,
    PatchedMatchGroupWriteRequestToJSON,
    Player,
    PlayerFromJSON,
    PlayerToJSON,
    RecentMatchesResponse,
    RecentMatchesResponseFromJSON,
    RecentMatchesResponseToJSON,
} from '../models';

export interface GroupsCreateRequest {
    matchGroupWriteRequest: MatchGroupWriteRequest;
}

export interface GroupsDestroyRequest {
    id: number;
}

export interface GroupsEditMatchesCreateRequest {
    id: number;
    matchId: string;
}

export interface GroupsEditMatchesDestroyRequest {
    id: number;
    matchId: string;
}

export interface GroupsForMatchListRequest {
    matchId: string;
}

export interface GroupsMatchesRetrieveRequest {
    id: number;
    offset?: number;
}

export interface GroupsPartialUpdateRequest {
    id: number;
    patchedMatchGroupWriteRequest?: PatchedMatchGroupWriteRequest;
}

export interface GroupsPlayersListRequest {
    id: number;
}

export interface GroupsRetrieveRequest {
    id: number;
}

export interface GroupsUpdateRequest {
    id: number;
    matchGroupWriteRequest: MatchGroupWriteRequest;
}

/**
 * 
 */
export class GroupsApi extends runtime.BaseAPI {

    /**
     * The base match group view set, to retrieve, edit and create match groups.
     */
    async groupsCreateRaw(requestParameters: GroupsCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MatchGroup>> {
        if (requestParameters.matchGroupWriteRequest === null || requestParameters.matchGroupWriteRequest === undefined) {
            throw new runtime.RequiredError('matchGroupWriteRequest','Required parameter requestParameters.matchGroupWriteRequest was null or undefined when calling groupsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/groups/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MatchGroupWriteRequestToJSON(requestParameters.matchGroupWriteRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MatchGroupFromJSON(jsonValue));
    }

    /**
     * The base match group view set, to retrieve, edit and create match groups.
     */
    async groupsCreate(requestParameters: GroupsCreateRequest, initOverrides?: RequestInit): Promise<MatchGroup> {
        const response = await this.groupsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * The base match group view set, to retrieve, edit and create match groups.
     */
    async groupsDestroyRaw(requestParameters: GroupsDestroyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling groupsDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/groups/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * The base match group view set, to retrieve, edit and create match groups.
     */
    async groupsDestroy(requestParameters: GroupsDestroyRequest, initOverrides?: RequestInit): Promise<void> {
        await this.groupsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * The base match group view set, to retrieve, edit and create match groups.
     */
    async groupsEditMatchesCreateRaw(requestParameters: GroupsEditMatchesCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling groupsEditMatchesCreate.');
        }

        if (requestParameters.matchId === null || requestParameters.matchId === undefined) {
            throw new runtime.RequiredError('matchId','Required parameter requestParameters.matchId was null or undefined when calling groupsEditMatchesCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/groups/{id}/edit_matches/{match_id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"match_id"}}`, encodeURIComponent(String(requestParameters.matchId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * The base match group view set, to retrieve, edit and create match groups.
     */
    async groupsEditMatchesCreate(requestParameters: GroupsEditMatchesCreateRequest, initOverrides?: RequestInit): Promise<void> {
        await this.groupsEditMatchesCreateRaw(requestParameters, initOverrides);
    }

    /**
     * The base match group view set, to retrieve, edit and create match groups.
     */
    async groupsEditMatchesDestroyRaw(requestParameters: GroupsEditMatchesDestroyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling groupsEditMatchesDestroy.');
        }

        if (requestParameters.matchId === null || requestParameters.matchId === undefined) {
            throw new runtime.RequiredError('matchId','Required parameter requestParameters.matchId was null or undefined when calling groupsEditMatchesDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/groups/{id}/edit_matches/{match_id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"match_id"}}`, encodeURIComponent(String(requestParameters.matchId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * The base match group view set, to retrieve, edit and create match groups.
     */
    async groupsEditMatchesDestroy(requestParameters: GroupsEditMatchesDestroyRequest, initOverrides?: RequestInit): Promise<void> {
        await this.groupsEditMatchesDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * The base match group view set, to retrieve, edit and create match groups.
     */
    async groupsForMatchListRaw(requestParameters: GroupsForMatchListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<MatchGroupRelative>>> {
        if (requestParameters.matchId === null || requestParameters.matchId === undefined) {
            throw new runtime.RequiredError('matchId','Required parameter requestParameters.matchId was null or undefined when calling groupsForMatchList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/groups/for_match/{match_id}/`.replace(`{${"match_id"}}`, encodeURIComponent(String(requestParameters.matchId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MatchGroupRelativeFromJSON));
    }

    /**
     * The base match group view set, to retrieve, edit and create match groups.
     */
    async groupsForMatchList(requestParameters: GroupsForMatchListRequest, initOverrides?: RequestInit): Promise<Array<MatchGroupRelative>> {
        const response = await this.groupsForMatchListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * The base match group view set, to retrieve, edit and create match groups.
     */
    async groupsListRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<MatchGroup>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/groups/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MatchGroupFromJSON));
    }

    /**
     * The base match group view set, to retrieve, edit and create match groups.
     */
    async groupsList(initOverrides?: RequestInit): Promise<Array<MatchGroup>> {
        const response = await this.groupsListRaw(initOverrides);
        return await response.value();
    }

    /**
     * The base match group view set, to retrieve, edit and create match groups.
     */
    async groupsMatchesRetrieveRaw(requestParameters: GroupsMatchesRetrieveRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RecentMatchesResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling groupsMatchesRetrieve.');
        }

        const queryParameters: any = {};

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/groups/{id}/matches/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RecentMatchesResponseFromJSON(jsonValue));
    }

    /**
     * The base match group view set, to retrieve, edit and create match groups.
     */
    async groupsMatchesRetrieve(requestParameters: GroupsMatchesRetrieveRequest, initOverrides?: RequestInit): Promise<RecentMatchesResponse> {
        const response = await this.groupsMatchesRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * The base match group view set, to retrieve, edit and create match groups.
     */
    async groupsPartialUpdateRaw(requestParameters: GroupsPartialUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MatchGroup>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling groupsPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/groups/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedMatchGroupWriteRequestToJSON(requestParameters.patchedMatchGroupWriteRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MatchGroupFromJSON(jsonValue));
    }

    /**
     * The base match group view set, to retrieve, edit and create match groups.
     */
    async groupsPartialUpdate(requestParameters: GroupsPartialUpdateRequest, initOverrides?: RequestInit): Promise<MatchGroup> {
        const response = await this.groupsPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * The base match group view set, to retrieve, edit and create match groups.
     */
    async groupsPlayersListRaw(requestParameters: GroupsPlayersListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Player>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling groupsPlayersList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/groups/{id}/players/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PlayerFromJSON));
    }

    /**
     * The base match group view set, to retrieve, edit and create match groups.
     */
    async groupsPlayersList(requestParameters: GroupsPlayersListRequest, initOverrides?: RequestInit): Promise<Array<Player>> {
        const response = await this.groupsPlayersListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * The base match group view set, to retrieve, edit and create match groups.
     */
    async groupsRetrieveRaw(requestParameters: GroupsRetrieveRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MatchGroup>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling groupsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/groups/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MatchGroupFromJSON(jsonValue));
    }

    /**
     * The base match group view set, to retrieve, edit and create match groups.
     */
    async groupsRetrieve(requestParameters: GroupsRetrieveRequest, initOverrides?: RequestInit): Promise<MatchGroup> {
        const response = await this.groupsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * The base match group view set, to retrieve, edit and create match groups.
     */
    async groupsUpdateRaw(requestParameters: GroupsUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MatchGroup>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling groupsUpdate.');
        }

        if (requestParameters.matchGroupWriteRequest === null || requestParameters.matchGroupWriteRequest === undefined) {
            throw new runtime.RequiredError('matchGroupWriteRequest','Required parameter requestParameters.matchGroupWriteRequest was null or undefined when calling groupsUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/groups/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MatchGroupWriteRequestToJSON(requestParameters.matchGroupWriteRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MatchGroupFromJSON(jsonValue));
    }

    /**
     * The base match group view set, to retrieve, edit and create match groups.
     */
    async groupsUpdate(requestParameters: GroupsUpdateRequest, initOverrides?: RequestInit): Promise<MatchGroup> {
        const response = await this.groupsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
