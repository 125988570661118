/* tslint:disable */
/* eslint-disable */
/**
 * ValData API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * The base user serializer, that displays most of the user's data.
 * @export
 * @interface UserWriteRequest
 */
export interface UserWriteRequest {
    /**
     * 
     * @type {string}
     * @memberof UserWriteRequest
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof UserWriteRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UserWriteRequest
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof UserWriteRequest
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof UserWriteRequest
     */
    profilePictureId?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserWriteRequest
     */
    linkedPlayers: Array<string>;
}

export function UserWriteRequestFromJSON(json: any): UserWriteRequest {
    return UserWriteRequestFromJSONTyped(json, false);
}

export function UserWriteRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserWriteRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'username': json['username'],
        'email': json['email'],
        'password': json['password'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'profilePictureId': !exists(json, 'profile_picture_id') ? undefined : json['profile_picture_id'],
        'linkedPlayers': json['linked_players'],
    };
}

export function UserWriteRequestToJSON(value?: UserWriteRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'username': value.username,
        'email': value.email,
        'password': value.password,
        'description': value.description,
        'profile_picture_id': value.profilePictureId,
        'linked_players': value.linkedPlayers,
    };
}

