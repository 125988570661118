/* tslint:disable */
/* eslint-disable */
/**
 * ValData API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * A basic read-only user serializer.
 * @export
 * @interface BasicUser
 */
export interface BasicUser {
    /**
     * 
     * @type {number}
     * @memberof BasicUser
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof BasicUser
     */
    readonly username: string;
    /**
     * 
     * @type {string}
     * @memberof BasicUser
     */
    readonly description: string;
    /**
     * 
     * @type {string}
     * @memberof BasicUser
     */
    readonly profilePictureId: string | null;
}

export function BasicUserFromJSON(json: any): BasicUser {
    return BasicUserFromJSONTyped(json, false);
}

export function BasicUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): BasicUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'username': json['username'],
        'description': json['description'],
        'profilePictureId': json['profile_picture_id'],
    };
}

export function BasicUserToJSON(value?: BasicUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}

