/* tslint:disable */
/* eslint-disable */
/**
 * ValData API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TimelineGroup
 */
export interface TimelineGroup {
    /**
     * 
     * @type {number}
     * @memberof TimelineGroup
     */
    time: number;
    /**
     * 
     * @type {number}
     * @memberof TimelineGroup
     */
    kills: number;
    /**
     * 
     * @type {number}
     * @memberof TimelineGroup
     */
    deaths: number;
    /**
     * 
     * @type {number}
     * @memberof TimelineGroup
     */
    plants: number;
    /**
     * 
     * @type {number}
     * @memberof TimelineGroup
     */
    defuses: number;
    /**
     * 
     * @type {number}
     * @memberof TimelineGroup
     */
    preplantAtkKills: number;
}

export function TimelineGroupFromJSON(json: any): TimelineGroup {
    return TimelineGroupFromJSONTyped(json, false);
}

export function TimelineGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimelineGroup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'time': json['time'],
        'kills': json['kills'],
        'deaths': json['deaths'],
        'plants': json['plants'],
        'defuses': json['defuses'],
        'preplantAtkKills': json['preplant_atk_kills'],
    };
}

export function TimelineGroupToJSON(value?: TimelineGroup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'time': value.time,
        'kills': value.kills,
        'deaths': value.deaths,
        'plants': value.plants,
        'defuses': value.defuses,
        'preplant_atk_kills': value.preplantAtkKills,
    };
}

