/* tslint:disable */
/* eslint-disable */
/**
 * ValData API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BasicUser,
    BasicUserFromJSON,
    BasicUserToJSON,
    EditRefreshedPlayersUserRequest,
    EditRefreshedPlayersUserRequestFromJSON,
    EditRefreshedPlayersUserRequestToJSON,
    Match,
    MatchFromJSON,
    MatchToJSON,
    ModeEnum,
    ModeEnumFromJSON,
    ModeEnumToJSON,
    PatchedUserWriteRequest,
    PatchedUserWriteRequestFromJSON,
    PatchedUserWriteRequestToJSON,
    Player,
    PlayerFromJSON,
    PlayerToJSON,
    User,
    UserFromJSON,
    UserToJSON,
    UserWriteRequest,
    UserWriteRequestFromJSON,
    UserWriteRequestToJSON,
} from '../models';

export interface UsersCreateRequest {
    userWriteRequest: UserWriteRequest;
}

export interface UsersDestroyRequest {
    username: string;
}

export interface UsersFavoriteCreateRequest {
    puuid: string;
}

export interface UsersFavoriteDestroyRequest {
    puuid: string;
}

export interface UsersMatchesListRequest {
    username: string;
    mode?: ModeEnum;
}

export interface UsersPartialUpdateRequest {
    username: string;
    patchedUserWriteRequest?: PatchedUserWriteRequest;
}

export interface UsersRefreshingUpdateRequest {
    editRefreshedPlayersUserRequest: EditRefreshedPlayersUserRequest;
}

export interface UsersRetrieveRequest {
    username: string;
}

export interface UsersUpdateRequest {
    username: string;
    userWriteRequest: UserWriteRequest;
}

/**
 * 
 */
export class UsersApi extends runtime.BaseAPI {

    /**
     * The base user view set, to retrieve, edit and create user data.
     */
    async usersCreateRaw(requestParameters: UsersCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<User>> {
        if (requestParameters.userWriteRequest === null || requestParameters.userWriteRequest === undefined) {
            throw new runtime.RequiredError('userWriteRequest','Required parameter requestParameters.userWriteRequest was null or undefined when calling usersCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserWriteRequestToJSON(requestParameters.userWriteRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * The base user view set, to retrieve, edit and create user data.
     */
    async usersCreate(requestParameters: UsersCreateRequest, initOverrides?: RequestInit): Promise<User> {
        const response = await this.usersCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve the current user.
     */
    async usersCurrentRetrieveRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<BasicUser>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/current/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BasicUserFromJSON(jsonValue));
    }

    /**
     * Retrieve the current user.
     */
    async usersCurrentRetrieve(initOverrides?: RequestInit): Promise<BasicUser> {
        const response = await this.usersCurrentRetrieveRaw(initOverrides);
        return await response.value();
    }

    /**
     * The base user view set, to retrieve, edit and create user data.
     */
    async usersDestroyRaw(requestParameters: UsersDestroyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.username === null || requestParameters.username === undefined) {
            throw new runtime.RequiredError('username','Required parameter requestParameters.username was null or undefined when calling usersDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/{username}/`.replace(`{${"username"}}`, encodeURIComponent(String(requestParameters.username))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * The base user view set, to retrieve, edit and create user data.
     */
    async usersDestroy(requestParameters: UsersDestroyRequest, initOverrides?: RequestInit): Promise<void> {
        await this.usersDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * The base user view set, to retrieve, edit and create user data.
     */
    async usersFavoriteCreateRaw(requestParameters: UsersFavoriteCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.puuid === null || requestParameters.puuid === undefined) {
            throw new runtime.RequiredError('puuid','Required parameter requestParameters.puuid was null or undefined when calling usersFavoriteCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/favorite/{puuid}/`.replace(`{${"puuid"}}`, encodeURIComponent(String(requestParameters.puuid))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * The base user view set, to retrieve, edit and create user data.
     */
    async usersFavoriteCreate(requestParameters: UsersFavoriteCreateRequest, initOverrides?: RequestInit): Promise<void> {
        await this.usersFavoriteCreateRaw(requestParameters, initOverrides);
    }

    /**
     * The base user view set, to retrieve, edit and create user data.
     */
    async usersFavoriteDestroyRaw(requestParameters: UsersFavoriteDestroyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.puuid === null || requestParameters.puuid === undefined) {
            throw new runtime.RequiredError('puuid','Required parameter requestParameters.puuid was null or undefined when calling usersFavoriteDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/favorite/{puuid}/`.replace(`{${"puuid"}}`, encodeURIComponent(String(requestParameters.puuid))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * The base user view set, to retrieve, edit and create user data.
     */
    async usersFavoriteDestroy(requestParameters: UsersFavoriteDestroyRequest, initOverrides?: RequestInit): Promise<void> {
        await this.usersFavoriteDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * The base user view set, to retrieve, edit and create user data.
     */
    async usersListRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<User>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserFromJSON));
    }

    /**
     * The base user view set, to retrieve, edit and create user data.
     */
    async usersList(initOverrides?: RequestInit): Promise<Array<User>> {
        const response = await this.usersListRaw(initOverrides);
        return await response.value();
    }

    /**
     * The base user view set, to retrieve, edit and create user data.
     */
    async usersMatchesListRaw(requestParameters: UsersMatchesListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Match>>> {
        if (requestParameters.username === null || requestParameters.username === undefined) {
            throw new runtime.RequiredError('username','Required parameter requestParameters.username was null or undefined when calling usersMatchesList.');
        }

        const queryParameters: any = {};

        if (requestParameters.mode !== undefined) {
            queryParameters['mode'] = requestParameters.mode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/{username}/matches/`.replace(`{${"username"}}`, encodeURIComponent(String(requestParameters.username))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MatchFromJSON));
    }

    /**
     * The base user view set, to retrieve, edit and create user data.
     */
    async usersMatchesList(requestParameters: UsersMatchesListRequest, initOverrides?: RequestInit): Promise<Array<Match>> {
        const response = await this.usersMatchesListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * The base user view set, to retrieve, edit and create user data.
     */
    async usersPartialUpdateRaw(requestParameters: UsersPartialUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<User>> {
        if (requestParameters.username === null || requestParameters.username === undefined) {
            throw new runtime.RequiredError('username','Required parameter requestParameters.username was null or undefined when calling usersPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/{username}/`.replace(`{${"username"}}`, encodeURIComponent(String(requestParameters.username))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedUserWriteRequestToJSON(requestParameters.patchedUserWriteRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * The base user view set, to retrieve, edit and create user data.
     */
    async usersPartialUpdate(requestParameters: UsersPartialUpdateRequest, initOverrides?: RequestInit): Promise<User> {
        const response = await this.usersPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * The base user view set, to retrieve, edit and create user data.
     */
    async usersRefreshingListRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Player>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/refreshing/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PlayerFromJSON));
    }

    /**
     * The base user view set, to retrieve, edit and create user data.
     */
    async usersRefreshingList(initOverrides?: RequestInit): Promise<Array<Player>> {
        const response = await this.usersRefreshingListRaw(initOverrides);
        return await response.value();
    }

    /**
     * The base user view set, to retrieve, edit and create user data.
     */
    async usersRefreshingUpdateRaw(requestParameters: UsersRefreshingUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Player>>> {
        if (requestParameters.editRefreshedPlayersUserRequest === null || requestParameters.editRefreshedPlayersUserRequest === undefined) {
            throw new runtime.RequiredError('editRefreshedPlayersUserRequest','Required parameter requestParameters.editRefreshedPlayersUserRequest was null or undefined when calling usersRefreshingUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/refreshing/`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: EditRefreshedPlayersUserRequestToJSON(requestParameters.editRefreshedPlayersUserRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PlayerFromJSON));
    }

    /**
     * The base user view set, to retrieve, edit and create user data.
     */
    async usersRefreshingUpdate(requestParameters: UsersRefreshingUpdateRequest, initOverrides?: RequestInit): Promise<Array<Player>> {
        const response = await this.usersRefreshingUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * The base user view set, to retrieve, edit and create user data.
     */
    async usersRetrieveRaw(requestParameters: UsersRetrieveRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<User>> {
        if (requestParameters.username === null || requestParameters.username === undefined) {
            throw new runtime.RequiredError('username','Required parameter requestParameters.username was null or undefined when calling usersRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/{username}/`.replace(`{${"username"}}`, encodeURIComponent(String(requestParameters.username))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * The base user view set, to retrieve, edit and create user data.
     */
    async usersRetrieve(requestParameters: UsersRetrieveRequest, initOverrides?: RequestInit): Promise<User> {
        const response = await this.usersRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * The base user view set, to retrieve, edit and create user data.
     */
    async usersUpdateRaw(requestParameters: UsersUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<User>> {
        if (requestParameters.username === null || requestParameters.username === undefined) {
            throw new runtime.RequiredError('username','Required parameter requestParameters.username was null or undefined when calling usersUpdate.');
        }

        if (requestParameters.userWriteRequest === null || requestParameters.userWriteRequest === undefined) {
            throw new runtime.RequiredError('userWriteRequest','Required parameter requestParameters.userWriteRequest was null or undefined when calling usersUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/{username}/`.replace(`{${"username"}}`, encodeURIComponent(String(requestParameters.username))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserWriteRequestToJSON(requestParameters.userWriteRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * The base user view set, to retrieve, edit and create user data.
     */
    async usersUpdate(requestParameters: UsersUpdateRequest, initOverrides?: RequestInit): Promise<User> {
        const response = await this.usersUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
