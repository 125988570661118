/* tslint:disable */
/* eslint-disable */
/**
 * ValData API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VersusDataLine
 */
export interface VersusDataLine {
    /**
     * 
     * @type {number}
     * @memberof VersusDataLine
     */
    _1: number;
    /**
     * 
     * @type {number}
     * @memberof VersusDataLine
     */
    _2: number;
    /**
     * 
     * @type {number}
     * @memberof VersusDataLine
     */
    _3: number;
    /**
     * 
     * @type {number}
     * @memberof VersusDataLine
     */
    _4: number;
    /**
     * 
     * @type {number}
     * @memberof VersusDataLine
     */
    _5: number;
}

export function VersusDataLineFromJSON(json: any): VersusDataLine {
    return VersusDataLineFromJSONTyped(json, false);
}

export function VersusDataLineFromJSONTyped(json: any, ignoreDiscriminator: boolean): VersusDataLine {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        '_1': json['_1'],
        '_2': json['_2'],
        '_3': json['_3'],
        '_4': json['_4'],
        '_5': json['_5'],
    };
}

export function VersusDataLineToJSON(value?: VersusDataLine | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        '_1': value._1,
        '_2': value._2,
        '_3': value._3,
        '_4': value._4,
        '_5': value._5,
    };
}

