/* tslint:disable */
/* eslint-disable */
/**
 * ValData API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Player
 */
export interface Player {
    /**
     * 
     * @type {string}
     * @memberof Player
     */
    puuid: string;
    /**
     * 
     * @type {boolean}
     * @memberof Player
     */
    readonly isFavorite: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof Player
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof Player
     */
    tag: string;
    /**
     * 
     * @type {number}
     * @memberof Player
     */
    tier?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Player
     */
    level: number;
    /**
     * 
     * @type {string}
     * @memberof Player
     */
    card: string;
    /**
     * 
     * @type {string}
     * @memberof Player
     */
    title?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Player
     */
    lastRefresh?: Date | null;
}

export function PlayerFromJSON(json: any): Player {
    return PlayerFromJSONTyped(json, false);
}

export function PlayerFromJSONTyped(json: any, ignoreDiscriminator: boolean): Player {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'puuid': json['puuid'],
        'isFavorite': json['is_favorite'],
        'username': json['username'],
        'tag': json['tag'],
        'tier': !exists(json, 'tier') ? undefined : json['tier'],
        'level': json['level'],
        'card': json['card'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'lastRefresh': !exists(json, 'last_refresh') ? undefined : (json['last_refresh'] === null ? null : new Date(json['last_refresh'])),
    };
}

export function PlayerToJSON(value?: Player | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'puuid': value.puuid,
        'username': value.username,
        'tag': value.tag,
        'tier': value.tier,
        'level': value.level,
        'card': value.card,
        'title': value.title,
        'last_refresh': value.lastRefresh === undefined ? undefined : (value.lastRefresh === null ? null : value.lastRefresh.toISOString()),
    };
}

