/* tslint:disable */
/* eslint-disable */
/**
 * ValData API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GeneralStatsGroup,
    GeneralStatsGroupFromJSON,
    GeneralStatsGroupFromJSONTyped,
    GeneralStatsGroupToJSON,
} from './GeneralStatsGroup';
import {
    KillOrders,
    KillOrdersFromJSON,
    KillOrdersFromJSONTyped,
    KillOrdersToJSON,
} from './KillOrders';
import {
    TimelineGroup,
    TimelineGroupFromJSON,
    TimelineGroupFromJSONTyped,
    TimelineGroupToJSON,
} from './TimelineGroup';
import {
    VersusData,
    VersusDataFromJSON,
    VersusDataFromJSONTyped,
    VersusDataToJSON,
} from './VersusData';

/**
 * 
 * @export
 * @interface AdvancedStatResponse
 */
export interface AdvancedStatResponse {
    /**
     * 
     * @type {GeneralStatsGroup}
     * @memberof AdvancedStatResponse
     */
    stats: GeneralStatsGroup;
    /**
     * 
     * @type {Array<TimelineGroup>}
     * @memberof AdvancedStatResponse
     */
    timeline: Array<TimelineGroup>;
    /**
     * 
     * @type {VersusData}
     * @memberof AdvancedStatResponse
     */
    versus: VersusData;
    /**
     * 
     * @type {KillOrders}
     * @memberof AdvancedStatResponse
     */
    killOrders: KillOrders;
}

export function AdvancedStatResponseFromJSON(json: any): AdvancedStatResponse {
    return AdvancedStatResponseFromJSONTyped(json, false);
}

export function AdvancedStatResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AdvancedStatResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'stats': GeneralStatsGroupFromJSON(json['stats']),
        'timeline': ((json['timeline'] as Array<any>).map(TimelineGroupFromJSON)),
        'versus': VersusDataFromJSON(json['versus']),
        'killOrders': KillOrdersFromJSON(json['kill_orders']),
    };
}

export function AdvancedStatResponseToJSON(value?: AdvancedStatResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'stats': GeneralStatsGroupToJSON(value.stats),
        'timeline': ((value.timeline as Array<any>).map(TimelineGroupToJSON)),
        'versus': VersusDataToJSON(value.versus),
        'kill_orders': KillOrdersToJSON(value.killOrders),
    };
}

