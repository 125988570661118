import { CSSProperties, ImgHTMLAttributes } from "react";

import { PlayerCard } from "providers/ValorantApiProvider";

import playerCardImageUrl from "helpers/playerCardImageUrl";

export default function PlayerCardImage({
  cardId,
  card,
  className,
  style,
  loading,
}: {
  cardId?: string | null | undefined;
  card?: PlayerCard | undefined;
  className?: string;
  style?: CSSProperties;
  loading?: ImgHTMLAttributes<HTMLImageElement>["loading"];
}) {
  return (
    <img
      className={`w-100 h-100 rounded ${className ?? ""}`}
      src={playerCardImageUrl(cardId ?? card?.uuid)}
      alt={card?.displayName ?? ""}
      title={card?.displayName ?? ""}
      style={style}
      loading={loading}
    />
  );
}
