/* tslint:disable */
/* eslint-disable */
/**
 * ValData API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ModeEnum,
    ModeEnumFromJSON,
    ModeEnumToJSON,
    PatchedTeamWriteRequest,
    PatchedTeamWriteRequestFromJSON,
    PatchedTeamWriteRequestToJSON,
    RecentMatchesResponse,
    RecentMatchesResponseFromJSON,
    RecentMatchesResponseToJSON,
    Team,
    TeamFromJSON,
    TeamToJSON,
    TeamWriteRequest,
    TeamWriteRequestFromJSON,
    TeamWriteRequestToJSON,
} from '../models';

export interface TeamsCreateRequest {
    teamWriteRequest: TeamWriteRequest;
}

export interface TeamsDestroyRequest {
    id: number;
}

export interface TeamsMatchesRetrieveRequest {
    id: number;
    mode?: ModeEnum;
    offset?: number;
}

export interface TeamsPartialUpdateRequest {
    id: number;
    patchedTeamWriteRequest?: PatchedTeamWriteRequest;
}

export interface TeamsRetrieveRequest {
    id: number;
}

export interface TeamsUpdateRequest {
    id: number;
    teamWriteRequest: TeamWriteRequest;
}

/**
 * 
 */
export class TeamsApi extends runtime.BaseAPI {

    /**
     * The base team view set, to retrieve, edit and create teams.
     */
    async teamsCreateRaw(requestParameters: TeamsCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Team>> {
        if (requestParameters.teamWriteRequest === null || requestParameters.teamWriteRequest === undefined) {
            throw new runtime.RequiredError('teamWriteRequest','Required parameter requestParameters.teamWriteRequest was null or undefined when calling teamsCreate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/teams/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TeamWriteRequestToJSON(requestParameters.teamWriteRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamFromJSON(jsonValue));
    }

    /**
     * The base team view set, to retrieve, edit and create teams.
     */
    async teamsCreate(requestParameters: TeamsCreateRequest, initOverrides?: RequestInit): Promise<Team> {
        const response = await this.teamsCreateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * The base team view set, to retrieve, edit and create teams.
     */
    async teamsDestroyRaw(requestParameters: TeamsDestroyRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling teamsDestroy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/teams/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * The base team view set, to retrieve, edit and create teams.
     */
    async teamsDestroy(requestParameters: TeamsDestroyRequest, initOverrides?: RequestInit): Promise<void> {
        await this.teamsDestroyRaw(requestParameters, initOverrides);
    }

    /**
     * The base team view set, to retrieve, edit and create teams.
     */
    async teamsListRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Team>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/teams/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TeamFromJSON));
    }

    /**
     * The base team view set, to retrieve, edit and create teams.
     */
    async teamsList(initOverrides?: RequestInit): Promise<Array<Team>> {
        const response = await this.teamsListRaw(initOverrides);
        return await response.value();
    }

    /**
     * The base team view set, to retrieve, edit and create teams.
     */
    async teamsMatchesRetrieveRaw(requestParameters: TeamsMatchesRetrieveRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RecentMatchesResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling teamsMatchesRetrieve.');
        }

        const queryParameters: any = {};

        if (requestParameters.mode !== undefined) {
            queryParameters['mode'] = requestParameters.mode;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/teams/{id}/matches/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RecentMatchesResponseFromJSON(jsonValue));
    }

    /**
     * The base team view set, to retrieve, edit and create teams.
     */
    async teamsMatchesRetrieve(requestParameters: TeamsMatchesRetrieveRequest, initOverrides?: RequestInit): Promise<RecentMatchesResponse> {
        const response = await this.teamsMatchesRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * The base team view set, to retrieve, edit and create teams.
     */
    async teamsPartialUpdateRaw(requestParameters: TeamsPartialUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Team>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling teamsPartialUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/teams/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchedTeamWriteRequestToJSON(requestParameters.patchedTeamWriteRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamFromJSON(jsonValue));
    }

    /**
     * The base team view set, to retrieve, edit and create teams.
     */
    async teamsPartialUpdate(requestParameters: TeamsPartialUpdateRequest, initOverrides?: RequestInit): Promise<Team> {
        const response = await this.teamsPartialUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * The base team view set, to retrieve, edit and create teams.
     */
    async teamsRetrieveRaw(requestParameters: TeamsRetrieveRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Team>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling teamsRetrieve.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/teams/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamFromJSON(jsonValue));
    }

    /**
     * The base team view set, to retrieve, edit and create teams.
     */
    async teamsRetrieve(requestParameters: TeamsRetrieveRequest, initOverrides?: RequestInit): Promise<Team> {
        const response = await this.teamsRetrieveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * The base team view set, to retrieve, edit and create teams.
     */
    async teamsUpdateRaw(requestParameters: TeamsUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Team>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling teamsUpdate.');
        }

        if (requestParameters.teamWriteRequest === null || requestParameters.teamWriteRequest === undefined) {
            throw new runtime.RequiredError('teamWriteRequest','Required parameter requestParameters.teamWriteRequest was null or undefined when calling teamsUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwtAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/teams/{id}/`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TeamWriteRequestToJSON(requestParameters.teamWriteRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamFromJSON(jsonValue));
    }

    /**
     * The base team view set, to retrieve, edit and create teams.
     */
    async teamsUpdate(requestParameters: TeamsUpdateRequest, initOverrides?: RequestInit): Promise<Team> {
        const response = await this.teamsUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
