/* tslint:disable */
/* eslint-disable */
/**
 * ValData API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Player,
    PlayerFromJSON,
    PlayerFromJSONTyped,
    PlayerToJSON,
} from './Player';

/**
 * The base user serializer, that displays most of the user's data.
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {number}
     * @memberof User
     */
    readonly id: number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    profilePictureId?: string | null;
    /**
     * 
     * @type {Array<Player>}
     * @memberof User
     */
    linkedPlayers: Array<Player>;
    /**
     * 
     * @type {Array<Player>}
     * @memberof User
     */
    readonly favoritePlayers: Array<Player>;
}

export function UserFromJSON(json: any): User {
    return UserFromJSONTyped(json, false);
}

export function UserFromJSONTyped(json: any, ignoreDiscriminator: boolean): User {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'username': json['username'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'profilePictureId': !exists(json, 'profile_picture_id') ? undefined : json['profile_picture_id'],
        'linkedPlayers': ((json['linked_players'] as Array<any>).map(PlayerFromJSON)),
        'favoritePlayers': ((json['favorite_players'] as Array<any>).map(PlayerFromJSON)),
    };
}

export function UserToJSON(value?: User | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'username': value.username,
        'description': value.description,
        'profile_picture_id': value.profilePictureId,
        'linked_players': ((value.linkedPlayers as Array<any>).map(PlayerToJSON)),
    };
}

