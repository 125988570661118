/* tslint:disable */
/* eslint-disable */
/**
 * ValData API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    VersusDataLine,
    VersusDataLineFromJSON,
    VersusDataLineFromJSONTyped,
    VersusDataLineToJSON,
} from './VersusDataLine';

/**
 * 
 * @export
 * @interface VersusData
 */
export interface VersusData {
    /**
     * 
     * @type {VersusDataLine}
     * @memberof VersusData
     */
    _1: VersusDataLine | null;
    /**
     * 
     * @type {VersusDataLine}
     * @memberof VersusData
     */
    _2: VersusDataLine | null;
    /**
     * 
     * @type {VersusDataLine}
     * @memberof VersusData
     */
    _3: VersusDataLine | null;
    /**
     * 
     * @type {VersusDataLine}
     * @memberof VersusData
     */
    _4: VersusDataLine | null;
    /**
     * 
     * @type {VersusDataLine}
     * @memberof VersusData
     */
    _5: VersusDataLine | null;
}

export function VersusDataFromJSON(json: any): VersusData {
    return VersusDataFromJSONTyped(json, false);
}

export function VersusDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): VersusData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        '_1': VersusDataLineFromJSON(json['_1']),
        '_2': VersusDataLineFromJSON(json['_2']),
        '_3': VersusDataLineFromJSON(json['_3']),
        '_4': VersusDataLineFromJSON(json['_4']),
        '_5': VersusDataLineFromJSON(json['_5']),
    };
}

export function VersusDataToJSON(value?: VersusData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        '_1': VersusDataLineToJSON(value._1),
        '_2': VersusDataLineToJSON(value._2),
        '_3': VersusDataLineToJSON(value._3),
        '_4': VersusDataLineToJSON(value._4),
        '_5': VersusDataLineToJSON(value._5),
    };
}

