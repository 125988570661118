import { defaultConfig, developerMode } from "defaultConfig";
import React, { useContext } from "react";

import { Configuration } from "api";

import { useAuth } from "providers/AuthProvider";

import refreshFetch from "helpers/refreshFetch";

interface ProvideConfig {
  production: boolean;
  apiConfiguration: Configuration;
  locale: string;
}

const ConfigContext = React.createContext<ProvideConfig>(defaultConfig);

/**
 * Uses ConfigContext to provide a configuration to children
 */
function useConfig(): ProvideConfig {
  return useContext(ConfigContext);
}

interface ProvideConfigProps {
  children?: React.ReactNode;
}

/**
 * Provides configuration to children
 * @returns {React.Provider<ProvideConfig>}
 */
function ConfigProvider({ children }: ProvideConfigProps) {
  const auth = useAuth();

  const apiConfiguration = new Configuration({
    basePath: defaultConfig.apiConfiguration.basePath,
    accessToken: auth?.getAccess,
    fetchApi: auth ? refreshFetch(auth) : fetch,
  });

  const value = React.useMemo(
    () => ({
      production: !developerMode,
      apiConfiguration,
      locale: "en-gb",
    }),
    [auth]
  );

  return (
    <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>
  );
}

export { ConfigProvider, useConfig };
