import React, { useContext, useEffect, useMemo, useState } from "react";

import { BasicUser, UsersApi } from "api";

import { useAuth } from "providers/AuthProvider";
import { useConfig } from "providers/ConfigProvider";

type UserContextType = {
  currentUser: BasicUser | undefined;
  refresh: () => Promise<void>;
};

const CurrentUserContext = React.createContext<UserContextType>({
  currentUser: undefined,
  refresh: () => Promise.resolve(),
});

/**
 * Uses CurrentUserContext to provide current user to children
 */
function useCurrentUser(): UserContextType {
  return useContext(CurrentUserContext);
}

interface ProvideCurrentUserProps {
  children?: React.ReactNode;
}

function CurrentUserProvider({ children }: ProvideCurrentUserProps) {
  const config = useConfig();
  const { isAuthenticated } = useAuth();

  const [currentUser, setCurrentUser] = useState<BasicUser>();

  const refreshUser = () => {
    if (isAuthenticated) {
      const userApi = new UsersApi(config.apiConfiguration);
      return userApi.usersCurrentRetrieve().then(setCurrentUser);
    }
    setCurrentUser(undefined);
    return Promise.resolve();
  };

  useEffect(() => {
    refreshUser();
  }, [isAuthenticated]);

  const value = useMemo(
    () => ({ currentUser, refresh: refreshUser }),
    [currentUser]
  );

  return (
    <CurrentUserContext.Provider value={value}>
      {children}
    </CurrentUserContext.Provider>
  );
}

export { CurrentUserProvider, useCurrentUser };
