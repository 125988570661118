import useDarkMode from "@fisch0920/use-dark-mode";
import { NavDropdown } from "react-bootstrap";
import { IoMoon, IoSunny } from "react-icons/io5";

export default function LightModeSwitch() {
  const darkMode = useDarkMode(false);

  const darkModeSwitch = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    e.stopPropagation();
    darkMode.toggle();
  };

  return (
    <NavDropdown.Item
      onClick={darkModeSwitch}
      className="d-flex align-items-center"
    >
      {!darkMode.value ? (
        <>
          Light mode <IoSunny className="ms-auto" />
        </>
      ) : (
        <>
          Dark mode <IoMoon className="ms-auto" />
        </>
      )}
    </NavDropdown.Item>
  );
}
