import { Container, NavDropdown, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";

import { useAuth } from "providers/AuthProvider";
import { useCurrentUser } from "providers/CurrentUserProvider";

import playerCardImageUrl from "helpers/playerCardImageUrl";

import "./Navbar.css";
import LightModeSwitch from "./Navbar/LightModeSwitch";
import {
  PlayerSearchButton,
  PlayerSearchForm,
  PlayerSearchInput,
} from "./PlayerSearch";

export default function ValdataNavbar() {
  const { currentUser } = useCurrentUser();
  const { logout } = useAuth();
  return (
    <>
      <Navbar
        collapseOnSelect
        expand="md"
        bg="white"
        variant="light"
        className="shadow fixed-top"
      >
        <Container>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />

          <Navbar.Brand as={Link} className="order-md-0" to="/">
            ValData
          </Navbar.Brand>

          {currentUser ? (
            <NavDropdown
              align="end"
              title={
                <img
                  src={playerCardImageUrl(currentUser.profilePictureId)}
                  alt=""
                  className="rounded-circle"
                  style={{ height: "38px" }}
                />
              }
              id="collasible-nav-dropdown"
              className="order-md-3 p-0 ms-2"
            >
              <NavDropdown.Item as={Link} to="/account/" className="fw-bolder">
                {currentUser.username}
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <LightModeSwitch />
              <NavDropdown.Item onClick={logout}>Log out</NavDropdown.Item>
            </NavDropdown>
          ) : (
            <NavDropdown
              align="end"
              title={
                <div
                  className="theme-bg rounded-circle"
                  style={{ display: "inline-block", verticalAlign: "middle" }}
                >
                  <div style={{ minWidth: "38px", minHeight: "38px" }} />
                </div>
              }
              id="collasible-nav-dropdown"
              className="order-md-3 p-0 ms-2"
            >
              <NavDropdown.Item as={Link} to="/login/" className="fw-bolder">
                Log in
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/login/">
                Sign Up
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <LightModeSwitch />
            </NavDropdown>
          )}

          <Navbar.Collapse id="responsive-navbar-nav">
            <PlayerSearchForm className="d-flex ms-auto pt-3 pt-md-0">
              <PlayerSearchInput isLink className="me-2" />
              <PlayerSearchButton className="btn btn-primary" />
            </PlayerSearchForm>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* Placeholder navbar to fill the space */}
      <nav className="navbar navbar-expand-md">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">
            ValData
          </Link>
        </div>
      </nav>
    </>
  );
}
