import { configureRefreshFetch } from "refresh-fetch";

import { AuthContextType } from "providers/AuthProvider";

const shouldRefreshToken = (error: any) =>
  error.status === 401 && error.json.code === "token_not_valid";

const refreshFetch = (auth: AuthContextType) =>
  configureRefreshFetch({
    fetch: (url: string, opt: RequestInit): Promise<Response> => {
      const options = opt;
      const accessToken = auth.getAccess();
      if (accessToken) {
        options.headers = {
          ...options.headers,

          // Use local storage to get most recent access token
          Authorization: `Bearer ${auth.getAccess()}`,
        };
      }

      return fetch(url, options).then((response) => {
        if (response.ok) {
          return response;
        }
        return response
          .json()
          .then((json) => {
            const error = response;
            error.json = json;
            return Promise.reject(error);
          })
          .catch(() => Promise.reject(response));
      });
    },
    shouldRefreshToken,
    refreshToken: () => auth.refreshToken(),
  });

export default refreshFetch;
