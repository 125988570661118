/* tslint:disable */
/* eslint-disable */
/**
 * ValData API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TeamWriteRequest
 */
export interface TeamWriteRequest {
    /**
     * 
     * @type {string}
     * @memberof TeamWriteRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof TeamWriteRequest
     */
    imageId?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof TeamWriteRequest
     */
    players: Array<string>;
}

export function TeamWriteRequestFromJSON(json: any): TeamWriteRequest {
    return TeamWriteRequestFromJSONTyped(json, false);
}

export function TeamWriteRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeamWriteRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'imageId': !exists(json, 'image_id') ? undefined : json['image_id'],
        'players': json['players'],
    };
}

export function TeamWriteRequestToJSON(value?: TeamWriteRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'image_id': value.imageId,
        'players': value.players,
    };
}

